// const baseUrl = "http://13.232.173.103/api";
const baseUrl = "https://api.askitall.co/v1";

export const urls = {
  login: `${baseUrl}/auth/login`,
  signUp: `${baseUrl}/users/signup`,

  verifyOtp: `${baseUrl}/auth/verify-otp`,
  userDetails: `${baseUrl}/users/me`,
  mentorSignup: `${baseUrl}/mentors/signup`,
  mentorLogin: `${baseUrl}/mentors/login`,
  getAllMentors: `${baseUrl}/mentors`,
  getSingleUser: `${baseUrl}/users`,
  booking:`${baseUrl}/booking`,
  
  upcomingSessions: `${baseUrl}/users/appointments/upcoming`,
  completedSessions: `${baseUrl}/users/appointments/completed`,
  canceledSessions: `${baseUrl}/users/appointments/canceled`,
  getConsultants: (specialization) => `${baseUrl}/users/consultants?specialization=${specialization}`,
  book: `${baseUrl}/users/consultants/book`
};
