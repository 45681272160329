import React, { useState } from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const response = await axios.post(
        "https://api.askitall.co/v1/auth/login",
        {
          email,
          password,
        }
      );

      if (response.status === 200 || response.status === 201) {
        window.location.href = "/indexpage";
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("tokens", JSON.stringify(response.data.tokens));
      }
    } catch (error) {
      setError("Please provide valid Credentials");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white min-h-screen flex items-center justify-center text-black">
      {error && (
        <div className="absolute top-4 left-1/2 transform backdrop-blur-lg z-50 -translate-x-1/2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      <motion.div
        initial={{
          opacity: 0,
          y: 25,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 1.25,
          ease: "easeInOut",
        }}
        className="relative z-10 w-full max-w-2xl py-4 px-8"
      >
        <Heading />
        <SocialOptions />
        <Email
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
          isLoading={isLoading}
        />
        <Terms />
      </motion.div>

      <div className="w-full max-w-sm lg:max-w-xl flex flex-col justify-start items-start gap-y-4 my-5">
        <img src="https://askitall.co/logo.svg" alt="Logo" />
        <div className="p-4 bg-[#EB5757] rounded-lg flex flex-col justify-start items-start gap-y-4">
          <p className="text-white lg:text-sm">
            Ask It All guides and provides you a way ahead in this journey. We
            connect you with SEBI-registered analysts & advisors who will answer
            all your queries. Stop running in circles for your financial & legal
            doubts. Book one-on-one video calls to gain the financial insights &
            legal guidance you deserve, all at affordable rates.
          </p>
        </div>
      </div>
    </div>
  );
};

const Heading = () => (
  <div>
    <div className="flex items-center gap-x-1">
      <img src="https://askitall.co/logo.svg" alt="Logo" />
    </div>
    <div className="mb-9 mt-6 space-y-1.5">
      <h1 className="text-2xl font-semibold">Log in to your account</h1>
      <p className="text-zinc-400">
        Don't have an account?{" "}
        <a href="/signup" className="text-[#EB5757]">
          Create one.
        </a>
      </p>
    </div>
  </div>
);

const SocialOptions = () => (
  <div>
    <div className="mb-3 flex gap-3">
      {/* Social login buttons can be added here */}
    </div>
  </div>
);

const Email = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
  isLoading,
}) => {
  return (
    <form onSubmit={handleLogin}>
      <div className="mb-3">
        <label htmlFor="email-input" className="mb-1.5 block text-zinc-400">
          Email
        </label>
        <input
          id="email-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="your.email@provider.com"
          className="w-full rounded-md border bg-[#F7F7F7] px-3 py-2 placeholder-zinc-500 ring-1 ring-transparent transition-shadow"
          required
        />
      </div>
      <div className="mb-6">
        <label htmlFor="password-input" className="block text-zinc-400">
          Password
        </label>
        <input
          id="password-input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="••••••••••••"
          className="w-full rounded-md border bg-[#F7F7F7] px-3 py-2 placeholder-zinc-500 ring-1 ring-transparent transition-shadow"
          required
        />
      </div>
      <SplashButton type="submit" className="w-full" disabled={isLoading}>
        {isLoading ? <Loader /> : "Sign in"}
      </SplashButton>
    </form>
  );
};

const Terms = () => (
  <p className="mt-9 text-xs text-zinc-400">
    By signing in, you agree to our{" "}
    <a target="_blank" href="/termsandconditions" className="text-[#EB5757]">
      Terms & Conditions
    </a>{" "}
    and{" "}
    <a target="_blank" href="/refundPolicy" className="text-[#EB5757]">
      Refund Policy.
    </a>
  </p>
);

const SplashButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        "rounded-md bg-gradient-to-br from-[#EB5757] to-[#EB5757] px-4 py-2 text-lg text-zinc-50 transition-all hover:scale-[1.02] hover:ring-transparent active:scale-[0.98]",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

const Loader = () => (
  <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
  </div>
);

export default LoginPage;
