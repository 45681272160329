import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import ProfilePage from "../ProfilePage/ProfilePage";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { urls } from "../../../api/apiUrl";
import { makeApiCall } from "../../../api/config";

const Profile = () => {
  const data = [
    {
      name: 'Prashant Kumar Singh',
      date: '25/2/2023',
      image: '/dummyprofile.png',
      category: 'FRONTEND',
      concept: 'Understanding Concept Of React',
    },
    {
      name: 'Prashant Kumar Singh',
      date: '25/2/2023',
      image: '/dummyprofile.png',
      category: 'FRONTEND',
      concept: 'Understanding Concept Of React',
    },
    // ... more data objects
  ];

  let authData = JSON.parse(localStorage.getItem('tokens'));
  console.log(authData);
  let token = useSelector((state) => state?.user?.token);

  const [ userData, setUserData] = useState(null);
  const [ upcomingSessions, setUpcomingSessions] = useState([]);
  const [ pastSessions, setPastSessions] = useState([]);

  

  let navigate = useNavigate();

  useEffect(() => {
    console.log(token  || authData?.access.token)
    if(token  || authData?.access.token) {
      getUpcomingSession();
      getPastSession();
      getProfileData();
    } else {
      navigate('/login');
    }
  }, []);
  
  const getProfileData = async () => {
    await makeApiCall(
      "GET",
      `${urls.userDetails}`,
      null,
      authData?.access?.token
    ).then((data) => {
      setUserData(data);
    }).catch((error) => {
      const { response } = error;
      console.log(response);
    })
  }

  const getUpcomingSession = async () => {
    await makeApiCall(
      "GET",
      `${urls.upcomingSessions}`,
      null,
      authData?.access?.token
    ).then((data) => {
      setUpcomingSessions(data?.appointments);
    }).catch((error) => {
      const { response } = error;
      console.log(response);
    })
  }
  
  const getPastSession = async () => {
    await makeApiCall(
      "GET",
      `${urls.completedSessions}`,
      null,
      authData?.access?.token
    ).then((data) => {
      setPastSessions(data?.appointments);
    }).catch((error) => {
      const { response } = error;
      console.log(response);
    })
  }

  return (
    <Layout>
      <div className="flex justify-between w-full h-screen overflow-hidden">
        <div className="w-full hidden lg:flex flex-col h-full">
          {/* Fixed header section */}
          <div className="flex-shrink-0 p-6">
            <div className="flex items-center w-full gap-x-6 mb-6">
              <form className="w-full mx-auto">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66668 14C11.1645 14 14 11.1645 14 7.66671C14 4.1689 11.1645 1.33337 7.66668 1.33337C4.16887 1.33337 1.33334 4.1689 1.33334 7.66671C1.33334 11.1645 4.16887 14 7.66668 14Z"
                        stroke="#9E9E9E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.6667 14.6667L13.3333 13.3334"
                        stroke="#9E9E9E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full p-4 ps-10 text-sm text-gray-900 border border-[#CCCCCCCC] rounded-lg bg-white dark:placeholder-[#9E9E9E] placeholder:font-medium dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search a mentor here...."
                    required
                  />
                </div>
              </form>
              <img src="/filter.svg" className="w-7" alt="Filter" />
            </div>

            <div className="bg-[#EB5757] p-0 rounded-[20px] flex justify-between w-full overflow-hidden mb-6">
              <div className="p-5 flex flex-col justify-start gap-y-1">
                <p className="text-white uppercase font-normal text-xs">
                  Solve Doubts
                </p>
                <p className="text-white text-2xl font-medium">You have a</p>
                <p className="text-white text-2xl font-medium">100 credits</p>
                <button className="bg-black px-4 py-2 text-sm w-11/12 my-2 rounded-full text-white">
                  Add Credits
                </button>
              </div>
              <div>
                <img src="/stars.svg" alt="Stars" />
              </div>
            </div>

            <div className="flex flex-col justify-start items-start w-full gap-y-2 mb-6">
              <div>
                <p className="text-black font-medium text-lg">Analytics</p>
              </div>
              <div className="grid grid-cols-3 gap-x-4 w-full">
                <div className="bg-white rounded-lg w-full flex flex-col shadow-lg px-4 py-3 gap-y-2 justify-start">
                  <p className="font-medium text-xs">
                    27 Questions have been asked by you!
                  </p>
                  <p className="text-xs text-[#5F5F5F]">Ask some more!</p>
                </div>
                <div className="bg-white rounded-lg w-full flex flex-col shadow-lg px-4 py-3 gap-y-2 justify-start">
                  <p className="font-medium text-xs">
                    25 new Engineering mentors
                  </p>
                  <p className="text-xs text-[#5F5F5F]">Ask some more!</p>
                </div>
                <div className="bg-white rounded-lg w-full flex flex-col shadow-lg px-4 py-3 gap-y-2 justify-start">
                  <p className="font-medium text-xs">
                    3827 minutes spent with mentors!
                  </p>
                  <p className="text-xs text-[#5F5F5F]">Ask some more!</p>
                </div>
              </div>
            </div>
          </div>

          {/* Scrollable section */}
          <div className="flex-grow overflow-y-auto px-6 flex flex-col gap-y-4 hidescrollbar">
          <div className="">
              <div className="flex flex-row justify-between items-center w-full pb-4">
                <p className="text-black font-medium text-lg">Upcoming Sessions</p>
                <p className="text-[#3366CC] underline font-normal">See All</p>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-t-md shadow-inner">
                  <thead>
                    <tr>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Instructor Name & Date</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Category</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Concept</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white rounded-b-md px-2 shadow-inner ">
                    {upcomingSessions.map((item, index) => (
                      <tr key={index} className="py-2">
                        <td className="px-4 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <img className="h-10 w-10 rounded-full" src={item.image} alt="" />
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{item.consultantFirstName} {item.consultantLastName}</div>
                              <div className="text-sm text-gray-500">{item.date}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap">
                          <span className="inline-flex text-xs leading-5 font-normal px-4 rounded-full bg-red-100 text-[#EB5757]">
                            {item.specialization}
                          </span>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm text-gray-500">{item.question}</td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm font-medium">
                          <button className="bg-[#3366CC33] uppercase rounded-full font-normal py-1 px-4 text-xs text-[#3366CC]">
                            Show Details
                          </button>
                        </td>
                      </tr>
                    ))}
                    {
                      upcomingSessions.length <= 0 &&
                      <span className="text-center">No Data found</span>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="">
              <div className="flex flex-row justify-between items-center w-full pb-4">
                <p className="text-black font-medium text-lg">Past Sessions</p>
                <p className="text-[#3366CC] underline font-normal">See All</p>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-t-md shadow-inner">
                  <thead>
                    <tr>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Instructor Name & Date</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Category</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Concept</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white rounded-b-md px-2 shadow-inner ">
                    {pastSessions.map((item, index) => (
                      <tr key={index} className="py-2">
                        <td className="px-4 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <img className="h-10 w-10 rounded-full" src={item.image} alt="" />
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{item.consultantFirstName} {item.consultantLastName}</div>
                              <div className="text-sm text-gray-500">{item.date}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap">
                          <span className="inline-flex text-xs leading-5 font-normal px-4 rounded-full bg-red-100 text-[#EB5757]">
                          {item.specialization}
                          </span>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm text-gray-500">{item.question}</td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm font-medium">
                          <button className="bg-[#3366CC33] uppercase rounded-full font-normal py-1 px-4 text-xs text-[#3366CC]">
                            Show Details
                          </button>
                        </td>
                      </tr>
                    ))}
                    {
                      pastSessions.length <= 0 &&
                      <span className="text-center">No Data found</span>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ProfilePage user={userData} />
      </div>
    </Layout>
  );
};

export default Profile;