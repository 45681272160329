import React from "react";

const Sidebar = ({ closeSidebar }) => {
  const Mentors = [
    {
      img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
      title: "Manya",
    },
    {
      img: "https://flowbite.com/docs/images/people/profile-picture-4.jpg",
      title: "Tanya",
    },
    {
      img: "https://flowbite.com/docs/images/people/profile-picture-2.jpg",
      title: "Sanjay",
    },
  ];

  const location = window.location.pathname;

  return (
    <div className="w-[300px]  bg-white h-screen shadow-lg flex flex-col transition-all duration-300 ease-in-out lg:translate-x-0 ">
      <div className="p-4 border-b flex justify-between items-center">
        <h1 className="text-xl font-bold text-red-500">ASK-IT-ALL</h1>
        <button onClick={closeSidebar} className="lg:hidden">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <nav className="flex flex-col justify-between  h-screen">
        <div>
          <h2 className="px-4 py-2 text-sm font-semibold text-black">
            OVERVIEW
          </h2>
          <ul>
            {["Profile", "Bookings", "Advisors","Payment"].map((item) => (
              <li key={item} className="px-4 py-2 hover:bg-gray-100">
                <a
                  href={
                    item === "Profile"
                      ? "/indexpage"
                      : item === "Bookings"
                      ? "/bookings":
                       item === "Payment"
                      ? "/credit"
                      : "/advisor"
                  }
                  className={`flex items-center gap-x-2 font-medium text-gray-700 ${
                    location === `/${item.toLowerCase()}` ? "bg-gray-200" : ""
                  }`}
                >
                  <img
                    src={getIcon(item)}
                    className="w-4"
                    alt={`${item} icon`}
                  />
                  {item}
                </a>
              </li>
            ))}
          </ul>
          {/* <div>
            <h2 className="px-4 py-2 mt-4 text-sm font-semibold text-black">
              PREVIOUS MENTORS
            </h2>
            <ul>
              {Mentors.map((mentor) => (
                <li key={mentor.title} className="px-4 py-2 hover:bg-gray-100">
                  <a href="#" className="flex items-center text-gray-700">
                    <img
                      src={mentor.img}
                      alt={mentor}
                      className="w-6 h-6 rounded-full mr-2"
                    />
                    {mentor.title}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}
        </div>

        <div>
          <h2 className="px-4 py-2 mt-4 text-sm font-semibold text-black">
            SETTINGS
          </h2>
          <ul>
            {["Settings", "Logout"].map((item) => (
              <li key={item} className="px-4 py-2 hover:bg-gray-100">
                <a
                onClick={item === "Logout" ? () => localStorage.clear() : null}
                  href={item === "Logout" ? "/login" : "#"}
                  className={`flex items-center text-sm gap-x-2 ${
                    item === "Logout" ? "text-red-500" : "text-gray-700"
                  }`}
                >
                  <img src={getIcon(item)} className="w-4" />
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

const getIcon = (item) => {
  // You can replace these with actual icons
  switch (item) {
    case "Profile":
      return "/assets/sidebar/home.svg";
    case "Bookings":
      return "/assets/sidebar/bookings.svg";
    case "Advisors":
      return "/assets/sidebar/payment.svg";
    case "Payment":
      return "/assets/sidebar/history.svg";
    case "Report":
      return "/assets/sidebar/report.svg";
    case "Settings":
      return "/assets/sidebar/settings.svg";
    case "Logout":
      return "/assets/sidebar/logout.svg";
    default:
      return "•";
  }
};

export default Sidebar;
