import React, {useEffect, useState } from 'react'
import Layout from "../HomePage/Layout";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "../../api/config";
import { urls } from "../../api/apiUrl";
import { useSelector} from "react-redux";

const Advisor = () => {

  let authData = JSON.parse(localStorage.getItem('tokens'));
  let token = useSelector((state) => state?.user?.token);
  let navigate = useNavigate();
  const [consultants, setConsultants] = useState([]);
  

  useEffect(() => {
    if(authData?.access.token) {
      getAdvisor();
    } else {
      navigate('/login');
    }
  }, []);


  const getAdvisor = async() => {
    await makeApiCall(
      "GET",
      `${urls.getConsultants('Finance')}`,
      null,
      authData?.access?.token
    ).then((data) => {
      setConsultants(data);
    }).catch((error) => {
      const { response } = error;
      if(error?.response?.data?.message === 'Please authenticate') {
        navigate('/login');
      }
      console.log(response);
    })
  }
  

  const AdvisorCard = ({ firstName, lastName, specialization, experience, appointmentCompleted, candidatesAdvised, chargePerMinutes, _id }) => (
    <div className="bg-white rounded-lg mb-4 shadow-md w-11/12 p-4">
      <div className="flex items-center mb-4">
        <img
          className="w-16 h-16 rounded-full mr-4"
          src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
          alt="Profile"
        />
        <div>
          <h2 className="text-xl font-semibold">{firstName} {lastName}</h2>
          <p className="text-gray-600">{specialization}</p>
          <p className="text-sm text-gray-500">Experience - {experience} Years</p>
          <div className="flex text-yellow-400">{"★".repeat(5)}</div>
        </div>
      </div>
      <div className="mb-4">
        <p className="text-gray-700">{appointmentCompleted} mins completed</p>
        <p className="text-gray-700">Advised {candidatesAdvised} candidates</p>
        <p className="font-semibold">₹{chargePerMinutes}/- per minute</p>
      </div>
      <button className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition duration-300" onClick={() => {
        navigate(`/pop/${_id}`)
      }}>
        Connect
      </button>
    </div>
  );

  const advisors = [
    { name: "Rohan Verma", expertise: "Finance Expert", experience: 3, minutesCompleted: 2500, candidatesAdvised: 12, ratePerMinute: 25 },
    { name: "Priya Singh", expertise: "Investment Advisor", experience: 5, minutesCompleted: 3000, candidatesAdvised: 15, ratePerMinute: 30 },
    { name: "Amit Patel", expertise: "Tax Consultant", experience: 4, minutesCompleted: 2800, candidatesAdvised: 10, ratePerMinute: 28 },
    { name: "Neha Gupta", expertise: "Financial Planner", experience: 6, minutesCompleted: 3500, candidatesAdvised: 18, ratePerMinute: 35 },
    { name: "Rahul Sharma", expertise: "Wealth Manager", experience: 4, minutesCompleted: 2700, candidatesAdvised: 14, ratePerMinute: 27 },
    { name: "Anita Desai", expertise: "Risk Analyst", experience: 5, minutesCompleted: 3200, candidatesAdvised: 16, ratePerMinute: 32 },
  ];
  return (
<Layout>
  <div className='flex flex-col justify-start items-start py-5 px-5 gap-y-3'>
<p className='text-black font-medium text-xl'>Finance</p>

<div className="flex flex-col lg:flex-row justify-between items-center w-full mb-4">
          <div className="w-full lg:w-3/5 mb-4 lg:mb-0">
            <form className="flex items-center">
              <div className="relative w-full">
                <input
                  type="search"
                  className="w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
                  placeholder="Search a mentor here...."
                  required
                />
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.66668 14C11.1645 14 14 11.1645 14 7.66671C14 4.1689 11.1645 1.33337 7.66668 1.33337C4.16887 1.33337 1.33334 4.1689 1.33334 7.66671C1.33334 11.1645 4.16887 14 7.66668 14Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.6667 14.6667L13.3333 13.3334"
                      stroke="#9E9E9E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <button type="button" className="p-2 ml-2">
                <img src="/filter.svg" className="w-7" alt="Filter" />
              </button>
            </form>
          </div>
          <div className="flex items-center space-x-4">
            <p>Balance Credits : $100</p>
            <button className="bg-red-500 py-2 px-4 rounded-md text-white">Add Credits</button>
          </div>
        </div>

        <div className='grid place-content-center justify-center gap-x-6 gap-y-1 px-2  sm:grid-cols-2 grid-cols-1 max-w-7xl w-full lg:grid-cols-3'>
        {consultants.map((advisor, index) => (
            <AdvisorCard key={index} {...advisor} />
          ))}
        </div>

        <nav className='mx-auto'>
  <ul class="flex w-full items-center -space-x-px h-10 text-base">
    <li>
      <a href="#" class="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-black bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700">
        <span class="sr-only">Previous</span>
        <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
        </svg>
      </a>
    </li>
    <li>
      <a href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-black bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">1</a>
    </li>
    <li>
      <a href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-black bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">2</a>
    </li>
    <li>
      <a href="#" aria-current="page" class="z-10 flex items-center justify-center px-4 h-10 leading-tight text-red-500 border border-gray-300 bg-red-50">3</a>
    </li>
    <li>
      <a href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-black bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">4</a>
    </li>
    <li>
      <a href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-black bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">5</a>
    </li>
    <li>
      <a href="#" class="flex items-center justify-center px-4 h-10 leading-tight text-black bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700">
        <span class="sr-only">Next</span>
        <svg class="w-3 h-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
      </a>
    </li>
  </ul>
</nav>
  </div>
</Layout>
  
  )
}

export default Advisor