import React from "react";

const WhyChooseCnntent = [
  {
    id: 1,
    heading: "Personalized Assistance",
    subheading:
      "Send in your questions beforehand for personalized guidance in your sessions. Connect and schedule one-on-one video calls with top-tier advisors at times that suit you best. Get clarity on building your portfolio, managing investments, and handling legal matters directly from advisors.",
    img: "/growth.svg",
  },
  {
    id: 2,
    heading: "Expert Advisory system",
    subheading:
      "Our network of advisors vary from SEBI-registered analysts to experienced legal consultants. Get clear, actionable advice insights on investments, law & financial planning.",
    img: "/box.svg",
  },
  {
    id: 3,
    heading: "Save on pockets",
    subheading:
      "Our Flexi-Pay System ensures you only pay for what you need, no hidden charges.",
    img: "/headphone.svg",
  },
];

const FeatureCard = ({ heading, subheading, img }) => (
  <div className="flex flex-col max-w-[400px] bg-white rounded-xl px-5 lg:px-[40px] py-4 lg:py-[24px] items-start justify-center gap-4">
    <div className="mb-4 text-center lg:text-start text-base w-full">
      <img src={img} className="w-12 lg:w-20 py-5 mx-auto" />
      <p className="text-center text-xl font-semibold md:mb-0 text-black">
        {heading}
      </p>
      <p className="font-normal text-center pt-4 text-sm text-[#3D3D3D]">
        {subheading}
      </p>
    </div>
  </div>
);

const Features = () => {
  return (
    <section  id="services" className="bg-gradient-to-bl from-[#EB5757]/10 to-[#FFFFFF] flex flex-col items-center justify-center w-full px-4 sm:px-6 lg:px-[100px] py-12 sm:py-16 md:py-16">
      <div className="w-full max-w-7xl">
        <div className="flex flex-col justify-center items-center gap-y-2 py-5">
          <p className=" text-center w-full sm:w-4/5 lg:w-[60%] mx-auto text-[#1F1F1F] text-xl sm:text-2xl md:text-4xl font-semibold">
            Why Choose Ask It All
          </p>
        </div>
        <div className="flex flex-col gap-y-3">
          <div className="flex flex-row items-center justify-center lg:px-0 px-5">
            <FeatureCard {...WhyChooseCnntent[0]} />
          </div>

          <div className="max-w-7xl mx-auto items-start w-full flex flex-col lg:gap-y-0 gap-y-4 lg:px-0 px-5 lg:flex-row justify-between">
            <FeatureCard {...WhyChooseCnntent[1]} />
            <div className="lg:block hidden">
              <img
                loading="lazy"
                src="https://i.ibb.co/27CpTY5/whychoosepmti.webp"
                className="w-full max-w-[300px] h-auto"
                alt="why_choose_pmti_Image"
              />
            </div>
            <FeatureCard {...WhyChooseCnntent[2]} />
          </div>
          <div className="lg:hidden block">
            <img
              loading="lazy"
              src="https://i.ibb.co/LzzxbkR/whychoosepmti.webp"
              className="w-full max-w-[200px] mx-auto h-auto"
              alt="why_choose_pmti_Image"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
