import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { HOMEPAGE } from "../../utils/constants";
import Coin from "../../assets/images/coins.png";
import Layout from "../HomePage/Layout";
import { useSelector, useDispatch } from "react-redux";
function AddCredit() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(-1);
  const [balance, setBalance] = useState(100); // Example initial balance
  const [message, setMessage] = useState(null);
  let authData = JSON.parse(localStorage.getItem('tokens'));
  let token = useSelector((state) => state?.user?.token);
  useEffect(() => {
    console.log(token  || authData?.access.token)
    if(token  || authData?.access.token) {
return null
    } else {
      navigate('/login');
    }
  }, []);
  const addAmount = (amount) => {
    setAmount(amount);
  };

  const addCredit = () => {
    if (amount > 0) {
      setBalance(prevBalance => prevBalance + amount);
      setMessage(`₹ ${amount} added successfully`);
      setAmount(-1); // Reset amount after adding
    }
  };

  return (
    <Layout>
      <div className="p-4 md:p-4">
        <div className="max-w-3xl w-full">
          {message && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
              <span className="block sm:inline">{message}</span>
            </div>
          )}
          <div className="mb-8">
            <h1 className="text-xl md:text-3xl font-medium mb-2">Add Credits</h1>
            <h2 className="text-lg md:text-xl">
              Available Balance ₹ {balance}
            </h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
            {[100, 200, 300, 400, 500, 600].map((value) => (
              <button
                key={value}
                onClick={() => addAmount(value)}
                className="bg-[#EB5757] text-white py-2 px-4 rounded"
              >
                ₹ {value}
              </button>
            ))}
          </div>
          <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex flex-col items-center mb-4">
              <img src={Coin} alt="Coin" className="w-1/2 p-5 mr-2" />
              <input
                type="number"
                placeholder="Enter Amount"
                className="w-full p-2 border bg-gray-100 shadow-inner rounded"
                value={amount === -1 ? "" : amount}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
            </div>
            <hr className="border-t border-gray-300 mb-4" />
            <div className="flex flex-row justify-center w-full space-x-4">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded"
                onClick={() => setAmount(-1)}
              >
                Cancel
              </button>
              <button
                className="bg-[#EB5757] text-white py-2 px-4 rounded"
                onClick={addCredit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddCredit;