import React from 'react';

const PolicyFormatter = ({ policyText }) => {
  // Function to split the text into sections
  const splitSections = (text) => {
    const sections = text.split(/\n(?=[A-Z][A-Z\s]+\n)/);
    return sections.map(section => {
      const [title, ...content] = section.split('\n');
      return { title: title.trim(), content: content.join('\n').trim() };
    });
  };

  // Function to render a section
  const renderSection = (section, index) => {
    const paragraphs = section.content.split('\n\n');
    return (
      <div key={index} className="mb-6">
        <h2 className="text-xl font-bold mb-2">{section.title}</h2>
        {paragraphs.map((paragraph, i) => (
          <p key={i} className="mb-2">
            {paragraph.split('\n').map((line, j) => (
              <React.Fragment key={j}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
        ))}
      </div>
    );
  };

  const sections = splitSections(policyText);

  return (
    <div className="max-w-7xl mx-auto px-4 py-4   ">
      {/* <h1 className="text-3xl font-medium mb-6">Askitall Cancellation & Refund Policy</h1> */}
      {sections.map((section, index) => renderSection(section, index))}
    </div>
  );
};

export default PolicyFormatter;