import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

const NavbarWebsite1 = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-2 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0 text-black flex flex-row items-center cursor-pointer gap-x-2" onClick={()=> window.location.href="/"} >
            <img className="w-full text-black" src="/logo.svg" alt="Logo" />
            {/* <p className='lg:text-2xl text-xl'>Askitall!</p> */}
          </div>
          <div className="hidden md:flex items-center justify-center flex-grow">
            <div className="flex items-baseline space-x-4">
              <a href="#" className="text-black px-3 py-2 rounded-md text-sm font-medium">Home</a>
              {/* <a href="#about" className="text-black px-3 py-2 rounded-md text-sm font-medium">About</a> */}
              <a href="#services" className="text-black px-3 py-2 rounded-md text-sm font-medium">Services</a>
              <a href="#faq" className="cursor-pointer text-black px-3 py-2 rounded-md text-sm font-medium">FAQ</a>
              <a href="/contact" className="cursor-pointer text-black px-3 py-2 rounded-md text-sm font-medium">Contact</a>
            </div>
          </div>
          <div className="hidden md:block">
            <button className="bg-[#EB5757] hover:bg-[#EB5757] text-white px-4 py-2 rounded-md text-sm font-medium" onClick={()=> window.location.href="/login"} >
              Register
            </button>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-white hover:bg-[#EB5757]  transition-all duration-150 delay-150 ease-linear focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`fixed top-0 z-20 left-0 w-64 h-full bg-white transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}>
        <div className="flex items-center justify-between px-4">
        <div className='flex items-center gap-2 flex-row'>
          <img className="h-24 w-24" src="/logo.svg" alt="Logo" />
          {/* <p>Askitall!</p> */}
        </div>
          <button
            onClick={() => setIsOpen(false)}
            className="text-gray-400 hover:text-black"
          >
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <a href="#" className="text-black block px-3 py-2 rounded-md text-base font-medium">Home</a>
          <a href="#" className="text-black block px-3 py-2 rounded-md text-base font-medium">About</a>
          <a href="#" className="text-black block px-3 py-2 rounded-md text-base font-medium">Services</a>
          <a href="/contact" className="text-black block px-3 py-2 rounded-md text-base font-medium">Contact</a>
        </div>
        <div className="px-4 mt-4">
          <button onClick={()=> window.location.href="https://askitforall.vercel.app/login"} className="w-full bg-[#EB5757] hover:bg-[#EB5757] text-white px-4 py-2 rounded-md text-sm font-medium">
            Register
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavbarWebsite1;