import React from "react";

const Company = () => {
  return (
    <div className="bg-[#FDEFEF] py-8 lg:py-10 ">
<section className="  gap-y-16 w-full flex flex-col items-center justify-center  mx-auto  max-w-7xl">
<p className="text-center text-xl">In Collaboration with</p>
    <div className=" mx-auto max-w-7xl w-full lg:px-0 px-4">
        <div className="grid grid-cols-2 mx-auto gap-[32px]   sm:gap-5 w-full md:grid-cols-2 lg:grid-cols-2">
            <a href="#" className="flex justify-center items-center">
            <img src="/rl.svg" className=" w-32 lg:w-40" />                                                                                                 
            </a>
            <a href="#" className="flex justify-center items-center">
            <img src="/FC.svg" className=" w-32 lg:w-40" />                                                                                                                                     
            </a>
            {/* <a href="#" className="flex justify-center items-center">
            <img src="/Nasa.svg" className=" w-32 lg:w-40" />                                                                                                                         
            </a>

            <a href="#" className="flex justify-center items-center">
            <img src="/walmart.svg" className=" w-32 lg:w-40" />                                                                                   
            </a> */}
       

        </div>
    </div>
</section>
    </div>
  );
};

export default Company;