import React from "react";
import NavbarWebsite from "./Navbar/NavbarWebsite1";

const TandC = () => {
  return (
    
    <div className="p-4">
    <NavbarWebsite/>
    <div className="max-w-7xl w-full mx-auto">


      <p className="lg:text-3xl text-left w-full  font-medium">
      TERMS & CONDITIONS 
      </p>
      1. <br/><br/>Terms and Conditions for using ASKITALL 1.1. <br/><br/>The
      following terms and conditions constitute an agreement between User and
      Askitall Private Limited (hereinafter referred to as “Company or Company”)
      the operator Askitall.co (“Website”). <br/><br/>By using or registering on the
      Website; User signify- a. <br/><br/>That, User agrees to the following terms &
      conditions, including that User consent to the terms of the Company’s
      Privacy Policy. <br/><br/>b. <br/><br/>That, User agree to comply with Indian laws as
      interpreted and applicable from time to time. <br/><br/>c. <br/><br/>That, the Website is
      offered 'AS IS' basis services and without warranties. <br/><br/>d. <br/><br/>That, User
      acknowledges and agrees that neither the Company nor any member registered
      on Askitall platform for advising or consulting user, including but not
      limited to Financial Experts ,Financial Advisor, Share Market Expert,
      Equity advisor, RIA (Registered Investment Advisor),Legal Consultant,
      Advocate, Lawyer, Legal Advisor, Chartered Accountants, Company Secretary
      or any other professional as the case may be (hereinafter referred to as
      'Advisor') shall not be held liable for any financial loss, damages, or
      adverse outcomes resulting from the User reliance on the advice or
      suggestions provided through the Website. <br/><br/>The User assumes full
      responsibility for any decisions made based on the consultation received,
      understanding that the Company and its Advisors do not guarantee the
      accuracy, suitability, or effectiveness of the information provided. <br/><br/>It is
      recommended that Users exercise caution and conduct their own research or
      seek professional financial advice before implementing any
      recommendations. <br/><br/>e. <br/><br/>That, Company is neither directly nor indirectly
      responsible for the consultation and assistance provided by an Advisor.
      The Company is only a technologically enabled service provider. <br/><br/>The
      Company is not bound by the services provided by an Advisor on the Website
      and Company will not be liable for any issues/discrepancies with the
      services so provided or damages or costs arising out advice provided by
      Advisor. <br/><br/>f. <br/><br/>That, all of the materials and information on the Website are
      provided for informational purposes only, and may not reflect current
      legal developments g. <br/><br/>That, on using or registering on the Website on
      behalf of a User (individual or entity) other than User, User authorize
      such individual or entity to accept these Terms & Conditions on User`s
      behalf. <br/><br/>1.2. <br/><br/>In addition, User understand that the specific method,
      features and services offered on Website may be altered, improved, removed
      or adapted at any time by Company in its sole discretion and without any
      notice. <br/><br/>If User do not agree with any of these terms, please do not use
      this Website. <br/><br/>2. <br/><br/>USING THE COMPANY WEBSITE 2.1. <br/><br/>Every User of the Website
      must be of or above 18 years of age. <br/><br/>In the case of a User below 18 years
      of age, a parent or guardian must accept this Agreement. <br/><br/>2.2. <br/><br/>The User
      must be responsible for ensuring that the information submitted is
      accurate. <br/><br/>The Company shall not engage in validating the information so
      provided. <br/><br/>The User shall be responsible for providing all or any of the
      information so required for providing services from time to time. <br/><br/>3. <br/><br/>HOW
      DOES IT WORK? 3.1. <br/><br/>That if the User opts for a Paid Service on the Website
      or by the Company, the User shall be charged a fee. <br/><br/>Which shall be paid by
      it to the Advisor and is a payment by which User compensate the Advisor
      for the time spent in providing User with services in a limited-scope
      Advisor-User relationship. <br/><br/>3.2. <br/><br/>That the fee so charged shall cover the
      Company’s technology maintenance cost and other costs involved in
      operating the Website. <br/><br/>3.3. <br/><br/>That the fee charged shall cover only services
      offered for a particular consultation, User may or may not engage the
      Advisor directly towards any further services User wish to avail from the
      respective Advisor. <br/><br/>Once the fees is paid, the User is intimated about the
      confirmed booking via email, SMS and / or phone. <br/><br/>COMPANY DO NOT IN ANY WAY
      SOLICIT, ENDORSE, PROMOTE ANY ADVISOR LISTED ON WEBSITE. <br/><br/>IN NO EVENT THE
      COMPANY SHALL COMPANY BE LIABLE TO THE User OR ANYONE ELSE FOR ANY
      DECISION MADE OR ACTION TAKEN BY THE User IN RELIANCE ON SUCH
      CONSULTATION. <br/><br/>4. <br/><br/>INDEPENDENCE OF ADVISORS 4.1. <br/><br/>The Company does not
      recommend or endorse any specific Advisors, products, procedures or
      opinions and the Consultation given by the Advisor via the Website shall
      be the independent opinion or statement of the Advisor and shall not
      reflect the opinion of the Company or its affiliates. <br/><br/>4.2. <br/><br/>All information
      is provided on an 'as-is' basis and the Company disclaims all warranties,
      either express or implied, including but not limited to the implied
      warranties of merchantability and fitness for particular purpose. <br/><br/>User
      hereby agree that, Advisors are not employed by or agents of the Company
      and neither the Company requires Advisors to provide any information about
      themselves nor does it rely upon and pass on this information to the User.
      4.3. <br/><br/>No listing of any Advisor on this Website, and no information
      provided by any Advisor, constitutes an endorsement, recommendation or
      referral by the Company. <br/><br/>The User is encouraged to investigate or perform
      his own due diligence concerning the Advisor and their qualifications. <br/><br/>5.
      MEMBERSHIP TERMS 5.1. <br/><br/>User represent and warrant that User is above
      eighteen years of age and that User possess the legal capacity to enter
      into agreements pertaining to Terms & Conditions and to register on the
      Company Services under User`s own name and to use the Company Services in
      accordance with the Terms & Conditions and abide by the obligations
      hereunder. <br/><br/>5.2. <br/><br/>Users are solely responsible for maintaining the
      confidentiality of their password and for all activities conducted within
      their account on the Company’s website. <br/><br/>Users agree to prohibit any
      unauthorized use of their password and promptly notify the Company of any
      security concerns or unauthorized use. <br/><br/>The Company holds no responsibility
      for any loss incurred due to the misuse of a user's account password. <br/><br/>5.3.
      International User acknowledge and agree that the Company may collect and
      use User Information and disclose it to other entities outside User`s
      resident jurisdiction. <br/><br/>In addition, such Information may be stored on
      servers located outside User`s resident jurisdiction. <br/><br/>By providing us with
      User Information, User acknowledge that User`s consent to the transfer of
      such Information outside User`s resident jurisdiction as detailed in
      Company Privacy Policy. <br/><br/>If User do not consent to such transfer, User may
      not use Company Content or Services. <br/><br/>5.4. <br/><br/>The Website may only be used in
      good faith and may not be used to transmit or otherwise make available any
      information that is false or that User`s do not have a right to make
      available under any law or under contractual relationships to threaten,
      abuse, harass, or invade the rights of any person or entity, to infringe
      on any person or entity's intellectual property rights, or in any other
      way that could reasonably be deemed unethical, illegal, or offensive. <br/><br/>5.5.
      The User`s may not misidentify or impersonate any person or entity, or
      falsely state or otherwise misrepresent User`s affiliation with a person
      or entity. <br/><br/>5.6. <br/><br/>Unless the User have Company’s prior written consent, it
      will not post advertisements or promotional materials, solicit
      participants and/or visitors of the Website, reproduce, duplicate, copy,
      sell, resell or exploit for any commercial purposes, any portion of the
      Website or its Services, use of the Website or it Services, or access to
      the Website or its Services. <br/><br/>5.7. <br/><br/>The User`s is prohibited from using any
      type of computer 'worm,' 'virus' or any other device that is intended or
      is likely to disrupt, overload, or otherwise impair the workings of any
      part of the Website. <br/><br/>If the User`s engage in such conduct, the resulting
      damage will be hard to quantify as a fixed amount and thus User`s hereby
      agree to pay us liquidated damages in the amount of INR 1,00,0000 for each
      day that the Website is damaged until the Website is completely repaired
      and further unquantified damages which will occur due to User`s act will
      be accessed and User`s shall be liable to pay the same. <br/><br/>This amount is
      derived by estimating the value of (1) the loss of good will caused by an
      inoperable website. <br/><br/>(2) the time and money it will take to repair the
      Website and to address the concerns of visitors. <br/><br/>Company are required to
      use reasonable efforts to repair the Website as quickly as possible. <br/><br/>This
      clause will not prohibit us from seeking additional compensation if User`s
      conduct injures us in a way not expressly contemplated herein. <br/><br/>5.8. <br/><br/>The
      User is not permitted to collect or store personal data about another
      Users. <br/><br/>The User is not permitted to access the Website for the purpose of
      data mining or extracting content from the Website. <br/><br/>5.9. <br/><br/>The User is
      permitted to use the Website to find an Advisor to perform permissible
      services for the User. <br/><br/>The User is not permitted to use the Website for
      any other reason. <br/><br/>Any impermissible use which shall include, but are not
      limited to, using the Website to order to solicit, hire, engage or
      otherwise work with the employees or affiliates of the Company or the
      Advisors, to interfere or attempt to interfere in the relationship between
      The Company and such employees, affiliates, Advisors, or for any other
      purpose other than described herein will constitute payment of liquidated
      damages in the amount of Rs.2,50,000 for each such impermissible use, and
      further unquantified damages which will occur due to User act will be
      accessed and it shall be liable to pay the same. <br/><br/>Nothing in this section
      is intended to limit the Company’s right to obtain injunctive and other
      relief as may be appropriate. <br/><br/>5.10. <br/><br/>The decision to form Advisor-User`s
      relationship is an important one and is between the User`s and the
      Advisor. <br/><br/>The User`s may wish to seek and retain the consultation of other
      Advisors so as to meet all applicable deadlines that govern User`s query /
      problem. <br/><br/>5.11. <br/><br/>The User hereby agree that, by taking any of the actions
      described above, it are soliciting and requesting a contact and as such,
      it may be contacted. <br/><br/>5.12. <br/><br/>The User affirmative act of using this Website
      and/or registering for the Website or the Services constitutes User`s
      electronic signature to these Terms & Conditions and User`s consent to
      enter into agreements as required. <br/><br/>6. <br/><br/>SCOPE OF SERVICES 6.1. <br/><br/>The Company,
      the Website and the Advisors offering services through the Website accept
      no responsibility for any medical, legal or financial events or outcomes
      related to services achieved through the use of the Website. <br/><br/>6.2. <br/><br/>The
      Company nor its Advisors shall be held liable for any financial loss,
      damages, or adverse outcomes resulting from the User's reliance on the
      advice or suggestions provided through the Website. <br/><br/>The User assumes full
      responsibility for any decisions made based on the consultation received,
      understanding that the Company and its Advisors do not guarantee the
      accuracy, suitability, or effectiveness of the information provided. <br/><br/>It is
      recommended that Users exercise caution and conduct their own research or
      seek professional financial advice before implementing any
      recommendations. <br/><br/>6.3. <br/><br/>The Company does not guarantee that a web-based
      consultation, over-phone consultation or in-person or video consultation
      is the appropriate way to solve its problem. <br/><br/>7. <br/><br/>USER`S PROFILE AT THE
      COMPANY 7.1. <br/><br/>The User Website Profile is created to store records of its
      consultations. <br/><br/>Any information provided as part of a web, over-phone or an
      in-person / video consultation becomes part of this record. <br/><br/>7.2. <br/><br/>The User
      agrees to provide accurate information to help us serve it best to Company
      knowledge, to periodically review such information and to update such
      information as and when necessary. <br/><br/>7.3. <br/><br/>The Company reserves the right to
      maintain, delete or destroy all communications and materials posted or
      uploaded to the Website according to its internal record retention and/or
      destruction policies. <br/><br/>7.4. <br/><br/>The User might be contacted via email to review
      the information provided by the User` for the Company Record or for Online
      Consultations. <br/><br/>Please make sure User provide a valid email-id and update
      it as and when needed. <br/><br/>8. <br/><br/>SUPPLEMENTARY COMMUNICATION 8.1. <br/><br/>Askitall.co
      after the consent of the User`s may send him/her newsletters and other
      information regarding common related topics or preventive care messages
      containing specific services’ related information, links to other related
      Websites and specific questions related to User`s Askitall.co Record.
      Additionally, Askitall.co makes available self-care informational services
      that provide general, and specific services’ information. <br/><br/>8.2.
      Above-mentioned communications and resources are not comprehensive and do
      not include complete potential information pertaining to the subject
      matter. <br/><br/>These are solely meant for general, educational and informational
      purposes and should not be relied upon as a substitute for User specific
      query / problem evaluation or construed, directly or indirectly as the
      practice of that particular service or dispensing of that particular
      service by Askitall.co. <br/><br/>8.3. <br/><br/>Company are not responsible for anything
      outside of Askitall.co. <br/><br/>Now or in the future, the Content and Services may
      link User`s to other web Websites or information, software, data, or other
      contents, on or off the Internet. <br/><br/>9. <br/><br/>PROTECTION OF PROPRIETARY INFORMATION
      9.1. <br/><br/>The User agrees that the Services, which include all software and
      information, both electronic and printed media, contain trade secrets and
      other proprietary intellectual property and are the proprietary property
      of Askitall.co. <br/><br/>They are protected by trademark laws and other proprietary
      rights and laws, and they may only be used or accessed as specifically
      provided for, in this Agreement. <br/><br/>9.2. <br/><br/>The User agrees that, user will not
      store, copy, modify, rent, lease, loan, sell, distribute, transfer,
      transmit, display, reverse-engineer, reverse-assemble, or otherwise
      attempt to discover any programming code or any source code used in or
      with the Services. <br/><br/>\ 9.3. <br/><br/>The User agrees that, user will not otherwise
      attempt to transfer any right in the Services (which includes its
      software, design and content), create derivative works based on it or in
      any manner commercially exploit the Services in whole or in part. <br/><br/>9.4. <br/><br/>The
      User agrees that in case of any violation of these trade secrets and / or
      other intellectual property protections or the terms of this Agreement
      will lead to prosecution under the Indian Laws. <br/><br/>9.5. <br/><br/>Only for the duration
      of being logged into the Consumers’ valid Askitall.co account, the
      consumer is hereby granted a revocable, non-exclusive, non- transferable
      license to use the services for personal, non-commercial purposes; subject
      to terms and qualifications of Agreements of Askitall.co. <br/><br/>9.6. <br/><br/>With the
      exception of User case records, Askitall.co retains all rights, titles and
      interests pertaining to Askitall.co, the Askitall.co Services and any
      content, products, documentation, software or other materials on the
      Askitall.co web Website along with any trade secret, trademark, service
      mark or other intellectual property or proprietary right in any of the
      foregoing. <br/><br/>9.7. <br/><br/>The User`s may not- a. <br/><br/>copy, print (except for the express
      permission), republish, display, transmit, distribute, sell, rent, lease,
      loan or otherwise make available in any form or by any means all or any
      portion of the Website, or any information or materials retrieved
      therefrom; b. <br/><br/>use the Website or any other materials from the Website to
      develop, or as a component of, an information storage and retrieval
      system, database, or similar information resource (in any media now
      existing or hereafter developed), that is offered for commercial
      distribution of any kind, including through sale, license, lease, rental,
      subscription, or any other commercial distribution mechanism; c. <br/><br/>create
      compilations or derivative works of the Website or any other materials
      from the Website; d. <br/><br/>use the Website or any other materials from the
      Website in any manner that may infringe any intellectual property right,
      or proprietary right of Askitall.co or any third parties; e. <br/><br/>remove,
      change, or obscure any proprietary notice or Terms & Conditions contained
      in the Website; f. <br/><br/>make any portion of the Website available through any
      time sharing system, service bureau, the Internet, or any other technology
      now existing or developed in the future, without Company express written
      consent; g. <br/><br/>remove, disable, defeat or change any functionality or
      appearance of the Website; h. <br/><br/>decompile, disassemble or reverse engineer
      any Website software or use any network monitoring or discovery software
      to determine the Website architecture; i. <br/><br/>use any automatic or manual
      process to harvest information from the Website; j. <br/><br/>use the Website for
      the purpose of gathering information for or transmitting- k. <br/><br/>unsolicited
      commercial email or SMS; l. <br/><br/>email or SMS that makes use of Company name or
      trademarks, including in connection with invalid or forged headers,
      invalid or non-existent domain names, or other means of deceptive
      addressing; and m. <br/><br/>unsolicited telephone calls or facsimile transmissions;
      n. <br/><br/>use the Website in a manner that violates any state or federal law
      regulating commercial email, facsimile transmissions or telephone
      solicitations; and o. <br/><br/>export or re-export the Website or any portion
      thereof, or any software available on or through the Website, in violation
      of the export control laws and regulations of the United States. <br/><br/>10.
      ETIQUETTE AND PRIVACY 10.1. <br/><br/>The resources of Askitall.co should not be
      used to impersonate another person or misrepresent authorization to act on
      behalf of others or Askitall.co. <br/><br/>All messages transmitted via Askitall.co
      should correctly identify the sender. <br/><br/>The User`s should never alter the
      attribution of origin in electronic mail messages or while posting any
      information. <br/><br/>10.2. <br/><br/>Askitall.co has zero-tolerance for unsolicited bulk
      email, bulk SMS, unsolicited posting to news groups, marketing
      gimmicks/spam/scams in community resources, or other illegal activities.
      10.3. <br/><br/>The User shall not infringe upon the rights of others, spam,
      propagate computer worms or viruses, use a false identity, attempt to gain
      unauthorized entry to any Website or network, infringe trademarks, or
      other intellectual property rights. <br/><br/>10.4. <br/><br/>The User further agrees to
      comply with Indian export laws concerning the transmission of technical
      data and other regulated materials via the World Wide Web. <br/><br/>10.5. <br/><br/>The User
      agrees to indemnify and hold Askitall.co harmless for any and all acts
      found or suspected to be in violation hereof. <br/><br/>The Consumer’s account will
      be terminated for any of the above infractions. <br/><br/>10.6. <br/><br/>The User shall not
      attempt to undermine the security or integrity of computing systems or
      networks of Askitall.co, its partners, or those accessed through or with
      their product, and must not attempt to gain unauthorized access. <br/><br/>10.7.
      Askitall.co reserves the right to terminate any account which doesn’t
      follow Company Terms and Conditions. <br/><br/>11. <br/><br/>ELECTRONIC PAYMENT 11.1.
      Askitall.co may from time-to-time contract with third party payment
      service providers,to facilitate the payment between User`s and Advisor and
      for collection of Platform Fees and other charges. <br/><br/>These third-party
      payment service providers may include third party banking or credit card
      payment gateways, payment aggregators, demand draft service providers,
      mobile payment service providers or through any facility as may be
      authorized by the Reserve Bank of India for collection, refund and
      remittance, as the case may be of payment or supporting the same in any
      manner. <br/><br/>11.2. <br/><br/>The User`s agrees, understands and confirms that the bank /
      debit / credit card details provided by the User`s for availing services
      of Askitall.co will be correct and accurate. <br/><br/>These details are entered by
      User`s directly at the third-party payment service provider webpage and
      Askitall.co does not have any access whatsoever to User`s payment details.
      12. <br/><br/>PAYMENT, CANCELLATION & REFUND POLICY Users are advised to carefully
      review the Payment, Cancellation & Refund Policy provided by the Company,
      which outlines specific terms and conditions governing payment
      transactions, cancellations, and refunds. <br/><br/>By engaging in transactions with
      the Company, Users agree to adhere to the terms outlined in the
      aforementioned policy. <br/><br/>User hereby acknowledged that in the event of any
      conflict or inconsistency between the terms of this Agreement and the
      Payment, Cancellation & Refund Policy, the terms of the policy shall
      prevail and be considered final. <br/><br/>13. <br/><br/>SUSPENSION DUE TO NON-COMPLIANCE WITH
      AGREEMENT Askitall.co will make all possible efforts to provide User the
      best services in consideration for User payment. <br/><br/>However, Askitall.co
      shall not incur any liability and shall declare any service guarantee void
      in event of one or more of the following circumstances: a. <br/><br/>If the User`s
      has not provided accurate details including contact information (User`s
      email and/or phone number), User`s name and Payment account information.
      b. <br/><br/>If the transaction for payment doesn’t go through with the User`s bank
      due to (including but not limited) insufficient funds, fraudulent access,
      failure of processing the request by Company Affiliate merchants or User`s
      bank. <br/><br/>c. <br/><br/>If circumstances beyond the control of Askitall.co (such as, but
      not limited to, fire, flood, or interference from external forces) prevent
      proper execution of the transaction. <br/><br/>14. <br/><br/>DISCLAIMERS 14.1. <br/><br/>User
      acknowledge that use of the Askitall.co Services is solely at User`s own
      risk, and that User assume full responsibility for all risks associated
      therewith. <br/><br/>To the extent that any of the Content included on Askitall.co
      is provided by third party content providers or other User, Company have
      no editorial control or responsibility of such content appearing on
      Askitall.co. <br/><br/>All information, products or services contained on or
      provided through this Website are provided 'as is', without any warranty
      of any kind, express or implied, to the fullest extent permissible under
      applicable law. <br/><br/>Askitall.co and its Affiliates hereby disclaim all
      representations and warranties, express or implied, statutory or
      otherwise, including but not limited to warranties of merchantability,
      appropriateness, title, non-infringement and freedom from virus strains.
      14.2. <br/><br/>Company reserve the right at any time and from time to time to
      modify or discontinue, temporarily or permanently, the Website (or any
      part of it) with or without notice. <br/><br/>In the event of a modification or
      discontinuance, all information submitted by User`s and others may be
      lost. <br/><br/>User agree to keep a permanent record of all information provided to
      us, and User agree that all information submitted to us is at User sole
      risk. <br/><br/>User agree that Company have no responsibility or liability for the
      deletion or failure to store any Content maintained or transmitted by the
      Website including without limitation messages and other communications.
      User agree that Company shall not be liable to User`s or to any third
      party for any modification, suspension or discontinuance of the Service.
      14.3. <br/><br/>Company do not represent or warrant that the Website will be
      error-free, free of viruses or other harmful components, or that defects
      will be corrected. <br/><br/>Company do not warrant or represent that the
      information available on or through the Website will be correct, accurate,
      timely, or otherwise reliable. <br/><br/>Company may (but are not obligated to) make
      improvements and/or changes to its features, functionality or content of
      the Website at any time. <br/><br/>In no event Company shall be liable for any such
      errors or defects. <br/><br/>15. <br/><br/>DISCLAIMER OF WARRANTIES 15.1. <br/><br/>User expressly
      understand and agree that: User use website at his own risk. <br/><br/>All content
      and services on the website are provided solely on an 'as-is/as-available'
      basis. <br/><br/>15.2. <br/><br/>To the extent permitted by applicable law, Company expressly
      disclaims all warranties of any kind, whether express or implied,
      including, but not limited to the implied warranties and conditions of
      merchantability, satisfactory quality, fitness for a particular purpose or
      use, as to the results User`s may achieve on account User`s use of the
      website, as to the results User may achieve on account of any relationship
      User elect to create with advisor / professional, and non-infringement.
      15.3. <br/><br/>Neither askitall.co nor any of its licensors or affiliates makes any
      representations or warranties regarding any advisor, including, without
      limitation, any advisor / professional’s ability to obtain a favorable
      result for User. <br/><br/>16. <br/><br/>LIMITATION OF LIABILITY 16.1. <br/><br/>User expressly
      understand and agree that Company shall not be liable for any, indirect,
      incidental, special, consequential or exemplary damages, including but not
      limited to, damages for loss of revenues, profits, goodwill, use, data,
      failure to realize expected savings, or other intangible losses (even if
      Company have been advised of the possibility of such damages), resulting
      from: a. <br/><br/>the use or the inability to use the website; b. <br/><br/>the cost of
      procurement of substitute goods and services resulting from any goods,
      data, information or services purchased or obtained or messages received
      or transactions entered into through or from the website; c. <br/><br/>invalid
      destinations, transmission errors, or unauthorized access to or alteration
      of User`s transmissions or data; d. <br/><br/>statements or conduct of any third
      party on the website; e. <br/><br/>User failure to receive any third party services
      or products requested through the website or f. <br/><br/>any other matter relating
      to the website. <br/><br/>In no event are Company liable to User`s for an amount in
      excess of the amount paid by User`s to us for the services in question, if
      any. <br/><br/>17. <br/><br/>INDEMNIFICATION 17.1. <br/><br/>The User shall defend, indemnify, save and
      hold Askitall.co, its affiliates harmless from all demands, liabilities,
      claims and expenses, including Advisor's fees, fees of third parties
      relating to or arising under this Agreement, the Services provided
      hereunder or User`s use of the Services, including without limitation,
      infringement by User`s or someone else using the service with User`s
      computer, of any intellectual property or other proprietary right of any
      person or entity, or from the violation of any of Company operating rules
      or policy relating to the Service(s) provided. <br/><br/>17.2. <br/><br/>Under no
      circumstances, including negligence, shall Askitall.co including (it’s
      Affiliates) or anyone else involved in creating, producing or distributing
      Askitall.co services be liable for any direct, indirect, incidental,
      special or consequential damages that result from the use of or inability
      to use Askitall.co Services, or that results from mistakes, omissions,
      interruptions, deletion of files, errors, defects, delays in operation, or
      transmission or any failure of performance, whether or not limited to acts
      of God, communication failure, theft, destruction or unauthorized access
      to Askitall.co records, programs or services. <br/><br/>This paragraph shall apply
      to all content and functionality of Askitall.co Services. <br/><br/>17.3.
      Notwithstanding the above, the User exclusive remedies for all damages,
      losses and causes of actions whether in contract, including negligence or
      otherwise, shall not exceed the aggregate amount, which the Consumer paid
      directly to Askitall.co for the service in question. <br/><br/>18. <br/><br/>THIRD PARTY
      PROTECTION The indemnification and disclaimers provisions set forth above
      are for the benefit of Askitall.co and its affiliates. <br/><br/>Each of these
      individuals or entities shall have the right to assert and enforce those
      provisions directly against User, the Consumer on its own behalf. <br/><br/>19.
      JURISDICTION 19.1. <br/><br/>This Agreement constitutes the entire understanding of
      the parties, and is agreed to be entered into within the jurisdiction of
      Mumbai, India and shall be governed by and shall be construed in
      accordance with the laws of the Republic of India, without giving effect
      to any choice of law rules or principles. <br/><br/>19.2. <br/><br/>Any dispute with
      Askitall.co shall exclusively be subject to the jurisdiction of the
      appropriate Courts situated at Mumbai, India. <br/><br/>No party shall object to
      removal or prosecution of any litigation to a court within the
      jurisdiction of Mumbai, India. <br/><br/>19.3. <br/><br/>Any cause of action or claim User may
      have with respect to Askitall.co must be commenced within 1 (one) year
      after it arises, except to the extent such litigation is not enforceable.
      19.4. <br/><br/>To the fullest extent permitted by law, each party to this Terms &
      Conditions waives its or his or her right to a jury trial with respect to
      any dispute or other controversy arising from hereunder or User`s use of
      or access to the Askitall.co Services or Website. <br/><br/>19.5. <br/><br/>To the fullest
      extent permitted by law, each party to this Terms & Conditions waives its
      or his or her right to a jury trial with respect to any dispute or other
      controversy arising from hereunder or User`s use of or access to the
      Askitall.co Services or Website. <br/><br/>19.6. <br/><br/>Failure to exercise or delay in
      exercising any right hereunder, or failure to insist upon or enforce
      strict performance of any provision of this Terms & Conditions, shall not
      be considered waiver thereof, which can only be made by signed writing. <br/><br/>No
      single waiver shall be considered a continuing or permanent waiver. <br/><br/>20.
      RIGHT TO OPERATIONAL FUNCTIONALITY 20.1. <br/><br/>Company reserves complete and
      sole discretion with respect to the operation of Company Services. <br/><br/>Company
      may, among other things withdraw, suspend or discontinue any functionality
      or feature of Company Services. <br/><br/>20.2. <br/><br/>By using the Website, User do not
      acquire any rights to the Website other than the limited license to use
      the Website that can be terminated in accordance with this section. <br/><br/>User
      agree that Company, in Company sole discretion, may terminate User`s
      password, account (or any part thereof) or use of the Website, and remove
      and discard any Content within the Website, for any reason, including,
      without limitation, non-payment, for lack of use, or if Company believe
      that User have violated or acted inconsistently with the letter or spirit
      of this agreement. <br/><br/>Company may also in Company sole discretion and at any
      time discontinue providing the Website, or any part thereof, with or
      without notice. <br/><br/>User`s agree that any termination of User`s access to the
      Website under any provision of this agreement may be affected without
      prior notice, and acknowledge and agree that Company may immediately
      deactivate or delete User`s account and all related Content information
      and files in User`s account and/or bar any further access to such files or
      the Website. <br/><br/>Further, User agree that Company shall not be liable to User
      or any third-party for any termination of User access to the Website. <br/><br/>21.
      RIGHT TO MODIFY TERMS OF SERVICE 21.1. <br/><br/>User agree and confirm that the
      Terms of Service may be amended from time to time without notice and take
      responsibility to update periodically with the latest version available on
      Askitall.co Website. <br/><br/>Any such revision or change will be binding and
      effective immediately on release of the revised Agreement or change to the
      Service(s) on Company Website. <br/><br/>Unless Company notifies otherwise, these
      terms incorporate and supersede any other terms associated with the
      Services. <br/><br/>21.2. <br/><br/>Askitall.co has the right to discontinue service, or deny
      access to anyone who violates Company policies or the terms and conditions
      stated herein, without prior notice or warning. <br/><br/>21.3. <br/><br/>Askitall.co may
      freely transfer, assign or delegate all or any part of this Terms &
      Conditions, and any rights and duties hereunder. <br/><br/>This Terms & Conditions
      will be binding upon and inure to the benefit of the heirs, successors and
      permitted assignees of the parties. <br/><br/>22. <br/><br/>NOTICE Askitall.co may provide
      notice to User- the Consumer, by e-mail to the e-mail address provided by
      User during registration, by a general notice on the Askitall.co Website.
      User may give notice to Askitall.co at any time via email to
      info@Askitall.co 23. <br/><br/>ASSIGNMENT Company may assign this contract at any
      time to any parent, subsidiary, or any affiliated company, or as part of
      the sale to, merger with, or other transfer of Company to another entity.
      Company will post a notice on the Website regarding any change of
      ownership so that User`s have the opportunity to discontinue User`s use of
      the Website or cancel User`s registration if User`s do not wish to
      continue to use the Website and the Services under the new ownership.
      User`s may not assign, transfer or sublicense these Terms & Conditions to
      anyone else and any attempt to do so in violation of this section shall be
      null and void. <br/><br/>24. <br/><br/>WAIVER AND SEVERABILITY OF TERMS Company failure to
      exercise or enforce any right or provision of this agreement shall not
      constitute a waiver of such right or provision. <br/><br/>If any provision of the
      agreement is found by a court of competent jurisdiction to be invalid, the
      parties nevertheless agree that the court should endeavor to give effect
      to the parties' intentions as reflected in the provision, and the other
      provisions of the agreement shall remain in full force and effect. <br/><br/>25.
      ADVISOR / PROFESSIONAL ETHICS NOTICE If User are Advisor / Professional,
      participating in any aspect of this Website, User acknowledge that rules
      of professional conduct apply to all aspects of User`s participation and
      that User will abide by such rules. <br/><br/>The rules include, but are not limited
      to, the rules relating to advertising, solicitation of Users, unauthorized
      practice of User respective profession, and misrepresentations of fact.
      Company disclaims all responsibility for User compliance with these rules.
      The section titles in this agreement are for convenience only and have no
      legal or contractual effect. <br/><br/>26. <br/><br/>NON-CIRCUMVENTION All the registered
      Advisor on Askitall platform shall not, under any circumstances, seek to
      obtain contact details from the Users and/or Advisors using the Askitall
      and/or Askitall-Advisor app hereinafter referred as Askitall App nor shall
      they share any kind of contact details including but not limited to phone,
      email, twitter, facebook, office address etc. <br/><br/>with any User and or Advisor
      received any call through the platform. <br/><br/>All communications, financial
      transactions, and interactions between the professionals and the Users
      initiated through the Askitall must occur exclusively on Askitall
      platform's built-in communication tools. <br/><br/>This is to maintain the integrity
      of the platform, to ensure the privacy and security of all parties
      involved, and to comply with data protection laws and regulations. <br/><br/>Any
      circumvention of the platform directly or indirectly is not encouraged.
      Askitall reserves the right to seek details from the platform User`s and
      may choose to implement any kind of Artificial Intelligence equipped tool
      without compromising on the confidentiality of the platform to
      specifically to monitor non-circumvention. <br/><br/>This will be done while
      respecting the privacy of the Legal Professional and the User, in
      accordance with Askitall's Privacy Policy. <br/><br/>27. <br/><br/>REPORTING Users are
      encouraged to report any request for personal contact details from a Legal
      Professional to Askitall immediately. <br/><br/>Equally, Legal Professionals are
      required to report any unsolicited provision of personal contact details
      by a User. <br/><br/>28. <br/><br/>PRIVACY Askitall.co does its best to protect User`s privacy
      with respect to information not otherwise disclosed by User`s in questions
      submitted to Askitall.co.In connection with the submission of Free
      Questions and Paid Consultations, Company only require, and Company
      encourage User`s to only provide, the minimum amount of personal
      identifying information necessary to process a question. <br/><br/>All of User`s
      personal information provided to Askitall.co, including email addresses,
      is concealed from all other User of Askitall.co and is never published as
      part of the Askitall.co public database. <br/><br/>Only the Askitall.co
      administrators have access, for purposes of Website administration only,
      to this information. <br/><br/>Askitall.co will not disclose this information to
      third parties without User`s permission, except in the following
      circumstances: a. <br/><br/>to protect ourselves from liability, or b. <br/><br/>to respond to
      legal process or to otherwise comply with the law. <br/><br/>With respect to certain
      information supplied by User`s, as explained further below, there will be
      a need for disclosure to Advisors / Professionals when User`s have booked
      a paid consultation. <br/><br/>Whether other information submitted to Askitall.co by
      User, such as the contents of the Question itself, will be publicly
      disclosed will vary as between Free Questions and Paid Consultations: 29.
      OWNERSHIP OF CONTENT By submitting a Free Question to Askitall.co, User`s
      understand and agree that User`s question and any answer provided by
      Advisor / Professional will become part of the Askitall.co public database
      and the sole property of Askitall.co. <br/><br/>Thus, as to all Free Questions and
      any answers, User`s agree that, without any compensation to User`s,
      Askitall.co will own all rights to the submitted questions and answers
      (including any intellectual property and moral rights) and will have the
      sole unrestricted right to use, reproduce, edit, modify, sell, adapt,
      publish, translate, create derivative works from, distribute, communicate
      to the public, perform and display the submitted content (in whole or in
      part) worldwide and/or to incorporate it in other works in any form,
      media, or technology now known or later developed, for the full term of
      any rights that may exist in the content. <br/><br/>30. <br/><br/>OFF-BOARDING Breach of this
      clause may lead to immediate suspension or termination of the
      Professional's Askitall account, with or without any prior notice. <br/><br/>31.
      ENTIRE AGREEMENT These Terms & Conditions, together with the privacy
      policy, which shall be deemed to be a part hereof and incorporated herein
      by reference represent the complete agreement between User`s and
      Askitall.co concerning the subject matter hereof, and it replaces all
      prior oral or written communications (if any) concerning such subject
      matter. <br/><br/>The Terms & Conditions shall commence upon the date User use the
      Website in any form. <br/><br/>This Terms & Conditions and the licenses granted
      hereunder shall be terminated without notice in the event User (or any
      authorized person using User account) fail to comply with the Terms and
      Conditions of this Terms & Conditions or the rules for use of the
      Askitall.co service promulgated by Askitall.co from time to time. <br/><br/>Company
      may also cancel or suspend User`s registration for any other reason,
      including inactivity for an extended period, but will attempt to notify
      User`s in advance of such cancellation or suspension. <br/><br/>Upon any
      termination, User`s will destroy all copies of Askitall.co materials in
      User`s possession and cease any access to or use of the Website. <br/><br/>Upon this
      suspension or termination Askitall.co may delete information, files, and
      other previously available content to User including but not limited to
      information provided by User. <br/><br/>Askitall.co shall not be liable to User or
      any third party for any termination of User`s access to the Website and/or
      the Services. <br/><br/>The provisions of this Terms & Conditions are severable, and
      in the event any provision hereof is determined to be invalid or
      unenforceable, such invalidity or unenforceability shall not affect the
      validity or enforceability of the remaining provisions, but such a
      provision shall be reformed, if reasonably possible, only to the extent
      necessary to make it enforceable. <br/><br/>The failure of Askitall.co to exercise
      or enforce any right or provision of these Terms & Conditions shall not
      constitute a waiver of such right or provision. <br/><br/>The failure of either
      party to exercise in any respect any right provided for herein shall not
      be deemed a waiver of any further rights hereunder. <br/><br/>User affirmative act
      of using this Website and/or registering for the Website or the Services
      constitutes User electronic signature to these Terms & Conditions, Privacy
      Policy that shall be deemed to be a part hereof and incorporated herein by
      reference and User`s consent to enter into agreements with us
      electronically. <br/><br/>I, the User`s/User Understand the risks and benefits of
      the online consultation Acknowledge and agree to assume the risk of the
      above-mentioned limitations pursuant to the use of Askitall.co. <br/><br/>I further
      understand and agree that no warranty or guarantee has been made to me
      concerning any consultation on particular problem, result, solution of my
      query / problem. <br/><br/>I understand that the laws that protect privacy and the
      confidentiality of personal and / or Professional Services' information
      also apply to Askitall.co, and that my information will be only used as
      defined by the Company`s Terms & Conditions and Privacy Statement. <br/><br/>I
      understand that the consultation through Askitall.co may involve
      electronic communication of my personal information to other Advisors who
      may be located in other areas, including out of state. <br/><br/>If User`s do not
      agree to Company`s Terms & Conditions ,Company`s Privacy Policy Company or
      Company`s Payment, Cancellation & Refund policy kindly User`s REFRAIN
      USING this Website. <br/><br/>
   <br/>
      Grievance officer Name : Chirag Shah
      <br/>
      Mobile : +91-9619408885 
      <br/>
      Email : care@Askitall.co 
    </div>
    </div>
  );
};

export default TandC;
