import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import useMeasure from "react-use-measure";

export const Faq = () => {
  const [selected, setSelected] = useState(TABS[0]);

  return (
    <section id="faq" className="overflow-hidden bg-gradient-to-b from-[#FDEEEE] to-[#FFFFFF] px-4 py-8 md:py-12 text-slate-50">
      <Heading />
      <Tabs selected={selected} setSelected={setSelected} />
      <Questions selected={selected} />
    </section>
  );
};

const Heading = () => {
  return (
    <>
      <div className="relative z-10 flex flex-col items-center justify-center">
        <span className="mb-4 md:mb-8 text-black font-medium text-center">
          Let's answer some questions
        </span>
        <span className="mb-6 md:mb-8 text-3xl md:text-5xl font-bold text-black text-center">FAQs</span>
      </div>

      {/* <span className="absolute -top-[250px] md:-top-[350px] left-[50%] z-0 h-[300px] md:h-[500px] w-[300px] md:w-[600px] -translate-x-[50%] rounded-full  blur-3xl" /> */}
    </>
  );
};

const Tabs = ({ selected, setSelected }) => {
  return (
    <div className="relative z-10 flex flex-wrap items-center justify-center gap-2 md:gap-4">
      {TABS.map((tab) => (
        <button
          onClick={() => setSelected(tab)}
          className={`relative overflow-hidden whitespace-nowrap rounded-md border-[1px] px-2 md:px-3 py-1 md:py-1.5 text-xs md:text-sm font-medium transition-colors duration-500 ${
            selected === tab
              ? "border-gray-800 text-black"
              : "border-slate-600 bg-transparent text-black"
          }`}
          key={tab}
        >
          <span className="relative z-10">{tab}</span>
          <AnimatePresence>
            {selected === tab && (
              <motion.span
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                exit={{ y: "100%" }}
                transition={{
                  duration: 0.5,
                  ease: "backIn",
                }}
                className="absolute inset-0 z-0 bg-white"
              />
            )}
          </AnimatePresence>
        </button>
      ))}
    </div>
  );
};

const Questions = ({ selected }) => {
  return (
    <div className="mx-auto mt-8 md:mt-12 max-w-3xl">
      <AnimatePresence mode="wait">
        {Object.entries(QUESTIONS).map(([tab, questions]) => {
          return selected === tab ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{
                duration: 0.5,
                ease: "backIn",
              }}
              className="space-y-3 md:space-y-4"
              key={tab}
            >
              {questions.map((q, idx) => (
                <Question key={idx} {...q} />
              ))}
            </motion.div>
          ) : undefined;
        })}
      </AnimatePresence>
    </div>
  );
};

const Question = ({ question, answer }) => {
  const [ref, { height }] = useMeasure();
  const [open, setOpen] = useState(false);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className={`rounded-xl border-[1px] border-slate-200 px-3 md:px-4 transition-colors ${
        open ? "bg-white shadow-inner" : "bg-white"
      }`}
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="flex w-full items-center justify-between gap-2 md:gap-4 py-3 md:py-4"
      >
        <span
          className={`text-left text-base md:text-lg font-medium transition-colors ${
            open ? "text-black" : "text-black"
          }`}
        >
          {question}
        </span>
        <motion.span
          variants={{
            open: {
              rotate: "45deg",
            },
            closed: {
              rotate: "0deg",
            },
          }}
        >
          <FiPlus
            className={`text-xl md:text-2xl transition-colors ${
              open ? "text-black" : "text-black"
            }`}
          />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? height : "0px",
          marginBottom: open ? "16px" : "0px",
        }}
        className="overflow-hidden text-sm md:text-base text-black transition-all delay-100 duration-100 ease-in-out"
      >
        <p ref={ref}>{answer}</p>
      </motion.div>
    </motion.div>
  );
};
const TABS = ["General", "Payments", "Legal Services", "Financial Services"];

const QUESTIONS = {
  "General": [
    {
      question: "What is the format of the advisory sessions?",
      answer:
        "The advisory sessions are conducted via video calls, allowing you to have a direct and personal consultation with your advisor at a pre-decided timing",
    },
    {
      question: "How does the credit system work?",
      answer:
        "Booking an advisor requires a certain number of credits, which can be purchased through our platform. The number of credits needed varies by advisor and session length.",
    },
    {
      question: "What is the duration of each session?",
      answer:
        "The duration of each session varies from 30 minutes to an hour, but you can book longer sessions if needed, based on your requirements and the advisor's availability.",
    },
    {
      question: "How do I provide feedback about the advisory service?",
      answer:
        "Feedback can be provided through our online feedback form, email, or directly to our customer service team. Your input is valuable for improving our services.",
    },
  ],
  "Financial Services": [
    {
      question: "What is the purpose of this platform?",
      answer:
        "Our service connects you with SEBI-registered analysts who provide expert financial advice through one-on-one video call sessions.",
    },
    {
      question: "Who are the advisors in this program?",
      answer:
        "Yes, we prioritize the confidentiality of your information. Our advisors adhere to strict privacy policies to ensure your data remains secure.",
    },
    {
      question: "What types of financial questions can I ask?",
      answer:
        "You can ask questions related to investment strategies, financial planning, stock market analysis, & get your portfolio enhanced.",
    },
    {
      question: "How do I book a session with an advisor??",
      answer:
        "You can book a session with an advisor through our website or app. Select your preferred advisor, choose a convenient time, and ensure you have the required credits to confirm your appointment",
    },
  ],
  "Payments": [
    {
      question: "What types of payment methods are accepted ?",
      answer:
        "We accept various payment methods, including credit/debit cards, online banking, and digital wallets. All transactions are secure and encrypted.",
    },
    {
      question: "Is there a refund policy in place?",
      answer:
        "Yes, we have a refund policy in place.Please review our refund policy for specific terms and conditions.",
    },
    {
      question: "How do I purchase credit?",
      answer:
        "You can purchase credits through our platform by selecting the desired credit package and completing the payment process.",
    },
    {
      question: "How do I check my credit balance?",
      answer:
        "Your credit balance can be viewed in your account dashboard on our website or app. It will reflect the remaining credits available for booking sessions.",
    },
  ],
  "Legal Services": [
    {
      question: "What types of legal questions can I ask?",
      answer:
        "You can ask questions related to corporate law, intellectual property, contract law, criminal law, family law, and more.",
    },
    {
      question: "Can I choose a specific advisor for my session?",
      answer:
        " Yes, you can choose an advisor of your choice and get familiar with their work experiences.",
    },
    {
      question: "Are the advisors available for follow-up questions after the session?",
      answer:
        "Follow-up questions can often be arranged directly with the advisor. Any additional fees or arrangements will be discussed during your session.",
    },
    {
      question: "How do I cancel or reschedule a session?",
      answer:
        "You can cancel or reschedule a session through our platform, subject to our cancellation policy. Please review the policy for specific terms and conditions.",
    },
  ],
};