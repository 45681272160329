import React from "react";
import ProfilePage from "../HomePage/ProfilePage/ProfilePage";
import Layout from "../HomePage/Layout";


const Bookings = () => {
  const data = [
    {
      name: 'Prashant Kumar Singh',
      date: '25/2/2023',
      image: '/dummyprofile.png',
      category: 'FRONTEND',
      concept: 'Understanding Concept Of React',
    },
    {
      name: 'Prashant Kumar Singh',
      date: '25/2/2023',
      image: '/dummyprofile.png',
      category: 'FRONTEND',
      concept: 'Understanding Concept Of React',
    },
    // ... more data objects
  ];

  return (
    <Layout>
    <div className="flex justify-between w-full h-screen overflow-hidden">
        <div className="w-full hidden lg:flex flex-col h-full">
          {/* Fixed header section */}
          <div className="flex-shrink-0 px-6 pt-2">
            <div className="flex items-center w-full gap-x-6 mb-6">
              <form className="w-full mx-auto">
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66668 14C11.1645 14 14 11.1645 14 7.66671C14 4.1689 11.1645 1.33337 7.66668 1.33337C4.16887 1.33337 1.33334 4.1689 1.33334 7.66671C1.33334 11.1645 4.16887 14 7.66668 14Z"
                        stroke="#9E9E9E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.6667 14.6667L13.3333 13.3334"
                        stroke="#9E9E9E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="default-search"
                    className="block w-full p-4 ps-10 text-sm text-gray-900 border border-[#CCCCCCCC] rounded-lg bg-white dark:placeholder-[#9E9E9E] placeholder:font-medium dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search a mentor here...."
                    required
                  />
                </div>
              </form>
              <img src="/filter.svg" className="w-7" alt="Filter" />
            </div>
          </div>

          {/* Scrollable section */}
          <p className="text-black font-medium text-xl pb-3 px-6">Bookings</p>
          <div className="flex-grow flex flex-col gap-y-4 overflow-y-auto px-6 hidescrollbar">
          <div className="">
              <div className="flex flex-row justify-between items-center w-full pb-4">
                <p className="text-black font-medium text-lg">Upcoming Sessions</p>
                <p className="text-[#3366CC] underline font-normal">See All</p>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-t-md shadow-inner">
                  <thead>
                    <tr>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Instructor Name & Date</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Category</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Concept</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white rounded-b-md px-2 shadow-inner ">
                    {data.map((item, index) => (
                      <tr key={index} className="py-2">
                        <td className="px-4 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <img className="h-10 w-10 rounded-full" src={item.image} alt="" />
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{item.name}</div>
                              <div className="text-sm text-gray-500">{item.date}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap">
                          <span className="inline-flex text-xs leading-5 font-normal px-4 rounded-full bg-red-100 text-[#EB5757]">
                            {item.category}
                          </span>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm text-gray-500">{item.concept}</td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm font-medium">
                          <button className="bg-[#3366CC33] uppercase rounded-full font-normal py-1 px-4 text-xs text-[#3366CC]">
                            Show Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="">
              <div className="flex flex-row justify-between items-center w-full pb-4">
                <p className="text-black font-medium text-lg">Past Sessions</p>
                <p className="text-[#3366CC] underline font-normal">See All</p>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-t-md shadow-inner">
                  <thead>
                    <tr>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Instructor Name & Date</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Category</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Concept</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white rounded-b-md px-2 shadow-inner ">
                    {data.map((item, index) => (
                      <tr key={index} className="py-2">
                        <td className="px-4 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <img className="h-10 w-10 rounded-full" src={item.image} alt="" />
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{item.name}</div>
                              <div className="text-sm text-gray-500">{item.date}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap">
                          <span className="inline-flex text-xs leading-5 font-normal px-4 rounded-full bg-red-100 text-[#EB5757]">
                            {item.category}
                          </span>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm text-gray-500">{item.concept}</td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm font-medium">
                          <button className="bg-[#3366CC33] uppercase rounded-full font-normal py-1 px-4 text-xs text-[#3366CC]">
                            Show Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="">
              <div className="flex flex-row justify-between items-center w-full pb-4">
                <p className="text-black font-medium text-lg">Cancelled Sessions</p>
                <p className="text-[#3366CC] underline font-normal">See All</p>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white rounded-t-md shadow-inner">
                  <thead>
                    <tr>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Instructor Name & Date</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Category</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Concept</th>
                      <th className="px-4 py-3 text-left text-sm font-medium text-[#3F3F3F] uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white rounded-b-md px-2 shadow-inner ">
                    {data.map((item, index) => (
                      <tr key={index} className="py-2">
                        <td className="px-4 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <img className="h-10 w-10 rounded-full" src={item.image} alt="" />
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{item.name}</div>
                              <div className="text-sm text-gray-500">{item.date}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap">
                          <span className="inline-flex text-xs leading-5 font-normal px-4 rounded-full bg-red-100 text-[#EB5757]">
                            {item.category}
                          </span>
                        </td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm text-gray-500">{item.concept}</td>
                        <td className="px-0 py-4 whitespace-nowrap text-sm font-medium">
                          <button className="bg-[#3366CC33] uppercase rounded-full font-normal py-1 px-4 text-xs text-[#3366CC]">
                            Show Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ProfilePage />
      </div>
    </Layout>
  );
};

export default Bookings;