import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
// import { Image } from '@mui/icons-material';

// 
const items = [
  {
    icon: <img width="40" height="40" src="https://img.icons8.com/ios/50/1-circle.png" alt="1-circle"/>,
    title: 'Create Your Profile',
    description:
      'Share your questions with top-tier advisors, and get your financial & legal queries resolved.',
    imageLight: '/1.png',

  },
  {
    icon:<img width="70" height="70" src="https://img.icons8.com/ios/50/2-circle.png" alt="2-circle"/>,
    title: 'Post/Answer a question',
    description:
      'Browse for SEBI-registered analysts for investments & advisors for legal matters.Review their profiles, skills, and client feedback to find the suitable option for your objectives.',
    imageLight: '/2.png',

  },
  {
    icon: <img width="90" height="90" src="https://img.icons8.com/ios/50/3-circle.png" alt="3-circle"/>,
    title: 'Book a video call',
    description:
      'Schedule one-on-one calls with an advisor specialized in your area of interest. During the call, you can discuss your financial concerns in detail, seek advice on investment strategies, or clarify legal aspects related to your business.',
    imageLight: '/3.png',

  },
];

export default function Expertise() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="about" sx={{ py: { xs: 8, sm: 16 } }} className='max-w-7xl w-full'>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" fontWeight={500} color="text.primary">
            Ask-it-all: Unlocking Wisdom
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
         We offer specialized expertise, flexible scheduling, and a user-friendly interface, 
         we re-define the learning experience.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                //   borderColor: selectedItemIndex === index ? '#EB5757' : 'transparent',
                  backgroundColor: selectedItemIndex === index ? '#EB5757' : '#EB57571A',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : 'inherit',
                  },
                  '&:hover': {
                    backgroundColor: selectedItemIndex === index ? '#EB5757' : '#EB57571A',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant=""
            border={"none"}
justifyContent="center"
alignItems="center"    
            sx={{
              display: { xs: 'auto', sm: 'none' },
              my: 4,
            }}
          >
       <Box >
        <img src={selectedFeature.imageLight}/>
       </Box>
            {/* <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 280,
              }}
            /> */}
            <Box sx={{ px: 2, py: 2 }}>
              <Typography color="#EB5757" variant="body2" fontSize={"23px"} fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="#EB5757"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
               
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? '#EB57571A' : undefined,
                //   borderColor: selectedItemIndex === index ? '#EB5757' : '#EB57571A',
                  '&:hover': {
                    backgroundColor: '#EB57571A',
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: selectedItemIndex === index ? '#EB5757' : 'text.secondary',
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontFamily={"Poppins"}
                      fontWeight="semibold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          border={"none"}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant=""
            border={"none"}
justifyContent="center"
alignItems="center"      
            sx={{

              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
           <img src={selectedFeature.imageLight}/>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}