import React from 'react';

const StatCard = ({ number, label, sublabel }) => (
  <div className="bg-white p-4 rounded-lg shadow-md text-center">
    <h2 className="text-3xl font-bold mb-1">{number}</h2>
    <p className="text-gray-600">{label}</p>
    {sublabel && <p className="text-sm text-gray-500">{sublabel}</p>}
  </div>
);

const Statssection = () => (
  <div className='bg-[#EB57571A]/10 py-5'>
  <div className="max-w-4xl mx-auto p-4">
    <h1 className="text-2xl font-bold text-center mb-6">
    It's how you monetize your audience!
    </h1>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <StatCard number="57+ " label="SEBI-Registered Analyst" sublabel={undefined} />
      <StatCard number="98%" label="Happy Clients Achieved Career Goals" sublabel={undefined} />
      <StatCard number="10k" label="Minutes of Expert Advice" sublabel={undefined} />
  </div>
    </div>
  </div>
);

export default Statssection;