import React from "react";

function TermsContent(props) {
  return (
    <div>
      <h1>Terms & Conditions for using ASKITALL</h1>

      <p>
        The following terms and conditions constitute an agreement between User
        and Askitall Private Limited (hereinafter referred to as “Company or
        Company”) the operator Askitall.co (“Website”). By using or registering
        on the Website, User signify:
      </p>

      <ul>
        <li>
          That User agrees to the following terms & conditions including that
          User consent to the terms of the Company’s Privacy Policy.
        </li>
        <li>
          That User agree to comply with Indian laws as interpreted and
          applicable from time to time.
        </li>
        <li>
          That the Website is offered 'AS IS' basis services and without
          warranties.
        </li>
        <li>
          That User acknowledges and agrees that neither the Company nor any
          member registered on Askitall platform for advising or consulting user
          including but not limited to Financial Experts, Financial Advisor,
          Share Market Expert, Equity advisor, RIA (Registered Investment
          Advisor), Legal Consultant, Advocate, Lawyer, Legal Advisor, Chartered
          Accountants, Company Secretary or any other professional as the case
          may be (hereinafter referred to as 'Advisor') shall not be held liable
          for any financial loss, damages or adverse outcomes resulting from the
          User reliance on the advice or suggestions provided through the
          Website. The User assumes full responsibility for any decisions made
          based on the consultation received understanding that the Company and
          its Advisors do not guarantee the accuracy, suitability or
          effectiveness of the information provided. It is recommended that
          Users exercise caution and conduct their own research or seek
          professional financial advice before implementing any recommendations.
        </li>
        <li>
          That Company is neither directly nor indirectly responsible for the
          consultation and assistance provided by an Advisor. The Company is
          only a technologically enabled service provider. The Company is not
          bound by the services provided by an Advisor on the Website and
          Company will not be liable for any issues/discrepancies with the
          services so provided or damages or costs arising out advice provided
          by Advisor.
        </li>
        <li>
          That all of the materials and information on the Website are provided
          for informational purposes only and may not reflect current legal
          developments.
        </li>
        <li>
          That on using or registering on the Website on behalf of a User
          (individual or entity) other than User, User authorize such individual
          or entity to accept these Terms & Conditions on User`s behalf.
        </li>
        <li>
          In addition, User understand that any method, features, and services
          offered on Website may be altered, improved, removed or adapted at any
          time by Company in its sole discretion and without any notice. If User
          do not agree with any of these terms, please do not use this Website.
        </li>
      </ul>

      <h2>Using the Company Website</h2>

      <ul>
        <li>
          Every User of the Website must be of or above 18 years of age. In the
          case of a User below 18 years of age, a parent or guardian must accept
          this Agreement.
        </li>
        <li>
          The User must be responsible for ensuring that the information
          submitted is accurate. The Company shall not engage in validating the
          information so provided. The User shall be responsible for providing
          all or any of the information so required for providing services from
          time to time.
        </li>
      </ul>

      <h2>How Does It Work?</h2>

      <ul>
        <li>
          The User registers on the Website providing necessary details to
          create an account.
        </li>
        <li>
          By registering, users acknowledge and agree to abide by all terms and
          conditions governing their use of the website and its services.
        </li>
        <li>
          If the User opts for a Paid Service on the Website or through the
          Company, all fee has to be paid upfront and through Askitall credit
          wallet before availing any paid service available at Askitall.co. User
          must have an active and valid Account to use Askitall Credit Wallet.
          The User adds Credit to their Askitall credit wallet through a
          third-party payment service.
        </li>
        <li>
          User can browse various Advisors and select one based on their needs
          and requirements and then request for booking of consulting services
          from the particular advisor. The User shall be charged a fee which
          shall be paid to the Advisor. This fee compensates the Advisor for the
          time spent providing services to the User within the limited scope of
          the Advisor-User relationship.
        </li>
        <li>
          That the fee so charged shall cover the Company’s technology
          maintenance cost and other costs involved in operating the Website and
          the Company.
        </li>
        <li>
          That the fee charged shall cover only services offered for a
          particular consultation. User may or may not engage the Advisor
          directly towards any further services User wish to avail from the
          respective Advisor. Once the fees is paid, the User is intimated about
          the confirmed booking via email, SMS and / or phone.
        </li>
      </ul>

      <h2>Independence of Advisors</h2>

      <p>
        The Company does not recommend or endorse any specific Advisors,
        products, procedures, or opinions and the Consultation given by the
        Advisor via the Website shall be the independent opinion or statement of
        the Advisor and shall not reflect the opinion of the Company or its
        affiliates.
      </p>

      <p>
        All information is provided on an 'as-is' basis and the Company
        disclaims all warranties, either express or implied, including but not
        limited to the implied warranties of merchantability and fitness for a
        particular purpose. User hereby agree that Advisors are not employed by
        or agents of the Company and neither the Company requires Advisors to
        provide any information about themselves nor does it rely upon and pass
        on this information to the User.
      </p>

      <p>
        No listing of any Advisor on this Website and no information provided by
        any Advisor constitutes an endorsement, recommendation, or referral by
        the Company. The User is encouraged to investigate or perform his own
        due diligence concerning the Advisor and their qualifications.
      </p>

      <h2>Membership Terms</h2>

      <ul>
        <li>
          User represent and warrant that User is above eighteen years of age
          and that User possess the legal capacity to enter into agreements
          pertaining to Terms & Conditions and to register on the Company
          Services under User`s own name and to use the Company Services in
          accordance with the Terms & Conditions and abide by the obligations
          hereunder.
        </li>
        <li>
          Users are solely responsible for maintaining the confidentiality of
          their password and for all activities conducted within their account
          on the Company’s website. Users agree to prohibit any unauthorized use
          of their password and promptly notify the Company of any security
          concerns or unauthorized use. The Company holds no responsibility for
          any loss incurred due to the misuse of a user's account password.
        </li>
        <li>
          International User acknowledge and agree that the Company may collect
          and use User Information and disclose it to other entities outside
          User`s resident jurisdiction. In addition, such Information may be
          stored on servers located outside User`s resident jurisdiction. By
          providing us with User Information, User acknowledge that User`s
          consent to the transfer of such Information outside User`s resident
          jurisdiction as detailed in Company Privacy Policy. If User do not
          consent to such transfer, User may not use Company Content or
          Services.
        </li>
      </ul>

      <h2>Supplementary Communication</h2>

      <p>
        Askitall.co, after the consent of the User, may send him/her newsletters
        and other information regarding common related topics or preventive care
        messages containing specific services’ related information, links to
        other related Websites, and specific questions related to User`s
        Askitall.co Record. Additionally, Askitall.co makes available self-care
        informational services that provide general and specific services’
        information.
      </p>

      <p>
        Above-mentioned communications and resources are not comprehensive and
        do not include complete potential information pertaining to the subject
        matter. These are solely meant for general educational and informational
        purposes and should not be relied upon as a substitute for User specific
        query/problem evaluation or construed directly or indirectly as the
        practice of that particular service or dispensing of that particular
        service by Askitall.co.
      </p>

      <p>
        Company are not responsible for anything outside of Askitall.co. Now or
        in the future, the Content and Services may link User to other web
        Websites or information, software, data, or other contents on or off the
        Internet.
      </p>

      <h2>Protection of Proprietary Information</h2>

      <p>
        The User agrees that the Services, which include all software and
        information both electronic and printed media, contain trade secrets and
        other proprietary intellectual property and are the proprietary property
        of Askitall.co. They are protected by trademark laws and other
        proprietary rights and laws and they may only be used or accessed as
        specifically provided for in this Agreement.
      </p>

      <p>
        The User agrees that user will not store, copy, modify, rent, lease,
        loan, sell, distribute, transfer, transmit, display, reverse-engineer,
        reverse-assemble, or otherwise attempt to discover any programming code
        or any source code used in or with the Services.
      </p>

      <p>
        The User agrees that user will not otherwise attempt to transfer any
        right in the Services (which includes its software, design, and
        content), create derivative works based on it, or in any manner
        commercially exploit the Services in whole or in part.
      </p>

      <p>
        The User agrees that in case of any violation of these trade secrets
        and/or other intellectual property protections or the terms of this
        Agreement will lead to prosecution under the Indian Laws.
      </p>

      <p>
        Only for the duration of being logged into the Consumers’ valid
        Askitall.co account, the consumer is hereby granted a revocable,
        non-exclusive, non-transferable license to use the services for
        personal, non-commercial purposes; subject to terms and qualifications
        of Agreements of Askitall.co.
      </p>

      <p>
        With the exception of User case records, Askitall.co retains all rights,
        titles, and interests pertaining to Askitall.co, the Askitall.co
        Services, and any content, products, documentation, software, or other
        materials on the Askitall.co web Website along with any trade secret,
        trademark, service mark, or other intellectual property or proprietary
        right in any of the foregoing.
      </p>

      <ul>
        <li>
          The User may not copy, print (except for the express permission),
          republish, display, transmit, distribute, sell, rent, lease, loan, or
          otherwise make available in any form or by any means all or any
          portion of the Website or any information or materials retrieved
          therefrom;
        </li>
        <li>
          The User may not use the Website or any other materials from the
          Website to develop or as a component of an information storage and
          retrieval system, database, or similar information resource (in any
          media now existing or hereafter developed) that is offered for
          commercial distribution of any kind, including through sale, license,
          lease, rental, subscription, or any other commercial distribution
          mechanism;
        </li>
        <li>
          The User may not create compilations or derivative works of the
          Website or any other materials from the Website;
        </li>
        <li>
          The User may not use the Website or any other materials from the
          Website in any manner that may infringe any intellectual property
          right or proprietary right of Askitall.co or any third parties;
        </li>
        <li>
          The User may not remove, change, or obscure any proprietary notice or
          Terms & Conditions contained in the Website;
        </li>
        <li>
          The User may not make any portion of the Website available through any
          time-sharing system, service bureau, the Internet, or any other
          technology now existing or developed in the future without Company
          express written consent;
        </li>
        <li>
          The User may not remove, disable, defeat, or change any functionality
          or appearance of the Website;
        </li>
        <li>
          The User may not decompile, disassemble, or reverse engineer any
          Website software or use any network monitoring or discovery software
          to determine the Website architecture;
        </li>
        <li>
          The User may not use any automatic or manual process to harvest
          information from the Website;
        </li>
        <li>
          The User may not use the Website for the purpose of gathering
          information for or transmitting unsolicited commercial email or SMS;
          email or SMS that makes use of Company name or trademarks, including
          in connection with invalid or forged headers, invalid or non-existent
          domain names, or other means of deceptive addressing; and unsolicited
          telephone calls or facsimile transmissions;
        </li>
        <li>
          The User may not use the Website in a manner that violates any state
          or federal law regulating commercial email, facsimile transmissions,
          or telephone solicitations; and export or re-export the Website or any
          portion thereof or any software available on or through the Website in
          violation of the export control laws and regulations of the United
          States.
        </li>
      </ul>

      <h2>Etiquette and Privacy</h2>

      <p>
        The resources of Askitall.co should not be used to impersonate another
        person or misrepresent authorization to act on behalf of others or
        Askitall.co. All messages transmitted via Askitall.co should correctly
        identify the sender. The User should never alter the attribution of
        origin in electronic mail messages or while posting any information.
      </p>

      <p>
        Askitall.co has zero-tolerance for unsolicited bulk email, bulk SMS,
        unsolicited posting to news groups, marketing gimmicks/spam/scams in
        community resources, or other illegal activities.
      </p>

      <p>
        The User shall not infringe upon the rights of others, spam, propagate
        computer worms or viruses, use a false identity, attempt to gain
        unauthorized entry to any Website or network, infringe trademarks or
        other intellectual property rights.
      </p>

      <p>
        The User further agrees to comply with Indian export laws concerning the
        transmission of technical data and other regulated materials via the
        World Wide Web.
      </p>

      <p>
        The User agrees to indemnify and hold Askitall.co harmless for any and
        all acts found or suspected to be in violation hereof. The Consumer’s
        account will be terminated for any of the above infractions.
      </p>

      <p>
        The User shall not attempt to undermine the security or integrity of
        computing systems or networks of Askitall.co, its partners, or those
        accessed through or with their product and must not attempt to gain
        unauthorized access.
      </p>

      <p>
        Askitall.co reserves the right to terminate any account which doesn’t
        follow Company Terms and Conditions.
      </p>

      <h2>Electronic Payment</h2>

      <p>
        Askitall.co may from time-to-time contract with third-party payment
        service providers to facilitate the payment between User and Advisor and
        for collection of Platform Fees and other charges. These third-party
        payment service providers may include third-party banking or credit card
        payment gateways, payment aggregators, demand draft service providers,
        mobile payment service providers, or through any facility as may be
        authorized by the Reserve Bank of India for collection, refund, and
        remittance as the case may be of payment or supporting the same in any
        manner.
      </p>

      <p>
        The User agrees, understands, and confirms that the bank / debit /
        credit card details provided by the User for availing services of
        Askitall.co will be correct and accurate. These details are entered by
        User directly at the third-party payment service provider webpage and
        Askitall.co does not have any access whatsoever to User payment details.
      </p>

      <h2>Payment Cancellation & Refund Policy</h2>

      <p>
        Users are advised to carefully review the Payment Cancellation & Refund
        Policy provided by the Company which outlines specific terms and
        conditions governing payment transactions, cancellations, and refunds.
        By engaging in transactions with the Company, Users agree to adhere to
        the terms outlined in the aforementioned policy. User hereby
        acknowledged that in the event of any conflict or inconsistency between
        the terms of this Agreement and the Payment Cancellation & Refund
        Policy, the terms of the policy shall prevail and be considered final.
      </p>

      <h2>Suspension Due to Non-Compliance with Agreement</h2>

      <p>
        Askitall.co will make all possible efforts to provide User the best
        services in consideration for User payment. However, Askitall.co shall
        not incur any liability and shall declare any service guarantee void in
        event of one or more of the following circumstances:
      </p>

      <ul>
        <li>
          If the User has not provided accurate details including contact
          information (User email and/or phone number), User name, and Payment
          account information.
        </li>
        <li>
          If the transaction for payment doesn’t go through with the User bank
          due to (including but not limited) insufficient funds, fraudulent
          access, failure of processing the request by Company Affiliate
          merchants, or User bank.
        </li>
        <li>
          If circumstances beyond the control of Askitall.co (such as but not
          limited to fire, flood, or interference from external forces) prevent
          proper execution of the transaction.
        </li>
      </ul>

      <h2>Disclaimers</h2>

      <p>
        User acknowledge that use of the Askitall.co Services is solely at User
        own risk and that User assume full responsibility for all risks
        associated therewith. To the extent that any of the Content included on
        Askitall.co is provided by third-party content providers or other User,
        Company have no editorial control or responsibility of such content
        appearing on Askitall.co. All information, products, or services
        contained on or provided through this Website are provided 'as is'
        without any warranty of any kind, express or implied, to the fullest
        extent permissible under applicable law. Askitall.co and its Affiliates
        hereby disclaim all representations and warranties, express or implied,
        statutory or otherwise, including but not limited to warranties of
        merchantability, appropriateness, title, non-infringement, and freedom
        from virus strains.
      </p>

      <p>
        Company reserve the right at any time and from time to time to modify or
        discontinue, temporarily or permanently, the Website (or any part of it)
        with or without notice. In the event of a modification or
        discontinuance, all information submitted by User and others may be
        lost. User agree to keep a permanent record of all information provided
        to us and User agree that all information submitted to us is at User
        sole risk. User agree that Company have no responsibility or liability
        for the deletion or failure to store any Content maintained or
        transmitted by the Website, including without limitation messages and
        other communications. User agree that Company shall not be liable to
        User or to any third party for any modification, suspension, or
        discontinuance of the Service.
      </p>

      <p>
        Company do not represent or warrant that the Website will be error-free,
        free of viruses or other harmful components, or that defects will be
        corrected. Company do not warrant or represent that the information
        available on or through the Website will be correct, accurate, timely,
        or otherwise reliable. Company may (but are not obligated to) make
        improvements and/or changes to its features, functionality, or content
        of the Website at any time. In no event Company shall be liable for any
        such errors or defects.
      </p>

      <h2>Disclaimer of Warranties</h2>

      <p>
        User expressly understand and agree that: User use website at his own
        risk. All content and services on the website are provided solely on an
        'as-is/as-available' basis.
      </p>

      <p>
        To the extent permitted by applicable law, Company expressly disclaims
        all warranties of any kind, whether express or implied, including but
        not limited to the implied warranties and conditions of merchantability,
        satisfactory quality, fitness for a particular purpose or use, as to the
        results User may achieve on account of User use of the website, as to
        the results User may achieve on account of any relationship User elect
        to create with advisor/professional and non-infringement.
      </p>

      <p>
        Neither askitall.co nor any of its licensors or affiliates makes any
        representations or warranties regarding any advisor, including without
        limitation any advisor/professional’s ability to obtain a favorable
        result for User.
      </p>

      <h2>Limitation of Liability</h2>

      <p>
        User expressly understand and agree that Company shall not be liable for
        any indirect, incidental, special, consequential, or exemplary damages,
        including but not limited to damages for loss of revenues, profits,
        goodwill, use, data, failure to realize expected savings, or other
        intangible losses (even if Company have been advised of the possibility
        of such damages) resulting from:
      </p>

      <ul>
        <li>The use or the inability to use the website;</li>
        <li>
          The cost of procurement of substitute goods and services resulting
          from any goods, data, information, or services purchased or obtained
          or messages received or transactions entered into through or from the
          website;
        </li>
        <li>
          Invalid destinations, transmission errors, or unauthorized access to
          or alteration of User transmissions or data;
        </li>
        <li>Statements or conduct of any third party on the website;</li>
        <li>
          User failure to receive any third-party services or products requested
          through the website or;
        </li>
        <li>
          Any other matter relating to the website. In no event are Company
          liable to User for an amount in excess of the amount paid by User to
          us for the services in question if any.
        </li>
      </ul>

      <h2>Indemnification</h2>

      <p>
        The User shall defend, indemnify, save and hold Askitall.co, its
        affiliates, harmless from all demands, liabilities, claims, and
        expenses, including Advisor's fees, fees of third parties, relating to
        or arising under this Agreement, the Services provided hereunder, or
        User use of the Services, including without limitation infringement by
        User or someone else using the service with User computer of any
        intellectual property or other proprietary right of any person or
        entity, or from the violation of any of Company operating rules or
        policy relating to the Service(s) provided.
      </p>

      <p>
        Under no circumstances, including negligence, shall Askitall.co,
        including its Affiliates or anyone else involved in creating, producing,
        or distributing Askitall.co services, be liable for any direct,
        indirect, incidental, special, or consequential damages that result from
        the use of or inability to use Askitall.co Services or that results from
        mistakes, omissions, interruptions, deletion of files, errors, defects,
        delays in operation or transmission, or any failure of performance,
        whether or not limited to acts of God, communication failure, theft,
        destruction, or unauthorized access to Askitall.co records, programs, or
        services. This paragraph shall apply to all content and functionality of
        Askitall.co Services.
      </p>

      <p>
        Notwithstanding the above, the User exclusive remedies for all damages,
        losses, and causes of actions whether in contract, including negligence
        or otherwise, shall not exceed the aggregate amount which the Consumer
        paid directly to Askitall.co for the service in question.
      </p>

      <h2>Third-Party Protection</h2>

      <p>
        The indemnification and disclaimers provisions set forth above are for
        the benefit of Askitall.co and its affiliates. Each of these individuals
        or entities shall have the right to assert and enforce those provisions
        directly against User the Consumer on its own behalf.
      </p>

      <h2>Jurisdiction</h2>

      <p>
        This Agreement constitutes the entire understanding of the parties and
        is agreed to be entered into within the jurisdiction of Mumbai, India,
        and shall be governed by and shall be construed in accordance with the
        laws of the Republic of India without giving effect to any choice of law
        rules or principles.
      </p>

      <p>
        Any dispute with Askitall.co shall exclusively be subject to the
        jurisdiction of the appropriate Courts situated at Mumbai, India. No
        party shall object to removal or prosecution of any litigation to a
        court within the jurisdiction of Mumbai, India.
      </p>

      <p>
        Any cause of action or claim User may have with respect to Askitall.co
        must be commenced within 1 (one) year after it arises except to the
        extent such litigation is not enforceable.
      </p>

      <p>
        To the fullest extent permitted by law, each party to this Terms &
        Conditions waives its or his or her right to a jury trial with respect
        to any dispute or other controversy arising from hereunder or User use
        of or access to the Askitall.co Services or Website.
      </p>

      <p>
        To the fullest extent permitted by law, each party to this Terms &
        Conditions waives its or his or her right to a jury trial with respect
        to any dispute or other controversy arising from hereunder or User use
        of or access to the Askitall.co Services or Website.
      </p>

      <p>
        Failure to exercise or delay in exercising any right hereunder or
        failure to insist upon or enforce strict performance of any provision of
        this Terms & Conditions shall not be considered waiver thereof which can
        only be made by signed writing. No single waiver shall be considered a
        continuing or permanent waiver.
      </p>

      <h2>Right to Operational Functionality</h2>

      <p>
        Company reserves complete and sole discretion with respect to the
        operation of Company Services. Company may among other things withdraw,
        suspend or discontinue any functionality or feature of Company Services.
      </p>

      <p>
        By using the Website, User do not acquire any rights to the Website
        other than the limited license to use the Website that can be terminated
        in accordance with this section. User agree that Company in Company sole
        discretion may terminate User password, account (or any part thereof) or
        use of the Website and remove and discard any Content within the Website
        for any reason, including without limitation, non-payment, for lack of
        use, or if Company believe that User have violated or acted
        inconsistently with the letter or spirit of this agreement. Company may
        also in Company sole discretion and at any time discontinue providing
        the Website or any part thereof with or without notice. User agree that
        any termination of User access to the Website under any provision of
        this agreement may be affected without prior notice and acknowledge and
        agree that Company may immediately deactivate or delete User account and
        all related Content, information, and files in User account and/or bar
        any further access to such files or the Website. Further, User agree
        that Company shall not be liable to User or any third party for any
        termination of User access to the Website.
      </p>

      <h2>Right to Modify Terms of Service</h2>

      <p>
        User agree and confirm that the Terms of Service may be amended from
        time to time without notice and take responsibility to update
        periodically with the latest version available on Askitall.co Website.
        Any such revision or change will be binding and effective immediately on
        release of the revised Agreement or change to the Service(s) on Company
        Website. Unless Company notifies otherwise, these terms incorporate and
        supersede any other terms associated with the Services.
      </p>

      <p>
        Askitall.co has the right to discontinue service or deny access to
        anyone who violates Company policies or the terms and conditions stated
        herein without prior notice or warning.
      </p>

      <p>
        Askitall.co may freely transfer, assign, or delegate all or any part of
        this Terms & Conditions and any rights and duties hereunder. This Terms
        & Conditions will be binding upon and inure to the benefit of the heirs,
        successors, and permitted assignees of the parties.
      </p>

      <h2>Notice</h2>

      <p>
        Askitall.co may provide notice to User- the Consumer by e-mail to the
        e-mail address provided by User during registration, by a general notice
        on the Askitall.co Website. User may give notice to Askitall.co at any
        time via email to info@Askitall.co
      </p>

      <h2>Assignment</h2>

      <p>
        Company may assign this contract at any time to any parent, subsidiary,
        or any affiliated company or as part of the sale to, merger with, or
        other transfer of Company to another entity. Company will post a notice
        on the Website regarding any change of ownership so that User have the
        opportunity to discontinue User use of the Website or cancel User
        registration if User do not wish to continue to use the Website and the
        Services under the new ownership. User may not assign, transfer, or
        sublicense these Terms & Conditions to anyone else and any attempt to do
        so in violation of this section shall be null and void.
      </p>

      <h2>Waiver and Severability of Terms</h2>

      <p>
        Company failure to exercise or enforce any right or provision of this
        agreement shall not constitute a waiver of such right or provision. If
        any provision of the agreement is found by a court of competent
        jurisdiction to be invalid, the parties nevertheless agree that the
        court should endeavor to give effect to the parties' intentions as
        reflected in the provision and the other provisions of the agreement
        shall remain in full force and effect.
      </p>

      <h2>Advisor / Professional Ethics Notice</h2>

      <p>
        If User are Advisor / Professional participating in any aspect of this
        Website, User acknowledge that rules of professional conduct apply to
        all aspects of User participation and that User will abide by such
        rules. The rules include but are not limited to the rules relating to
        advertising, solicitation of Users, unauthorized practice of User
        respective profession, and misrepresentations of fact. Company disclaims
        all responsibility for User compliance with these rules.
      </p>

      <p>
        The section titles in this agreement are for convenience only and have
        no legal or contractual effect.
      </p>

      <h2>Non-Circumvention</h2>

      <p>
        All the registered Advisor on Askitall platform shall not under any
        circumstances seek to obtain contact details from the Users and/or
        Advisors using the Askitall and/or Askitall-Advisor app, hereinafter
        referred as Askitall App, nor shall they share any kind of contact
        details including but not limited to phone, email, twitter, facebook,
        office address, etc., with any User and or Advisor received any call
        through the platform. All communications, financial transactions, and
        interactions between the professionals and the Users initiated through
        the Askitall must occur exclusively on Askitall platform's built-in
        communication tools. This is to maintain the integrity of the platform,
        to ensure the privacy and security of all parties involved, and to
        comply with data protection laws and regulations. Any circumvention of
        the platform directly or indirectly is not encouraged. Askitall reserves
        the right to seek details from the platform User and may choose to
        implement any kind of Artificial Intelligence equipped tool without
        compromising on the confidentiality of the platform to specifically to
        monitor non-circumvention. This will be done while respecting the
        privacy of the Legal Professional and the User in accordance with
        Askitall's Privacy Policy.
      </p>

      <h2>Reporting</h2>

      <p>
        Users are encouraged to report any request for personal contact details
        from a Legal Professional to Askitall immediately. Equally, Legal
        Professionals are required to report any unsolicited provision of
        personal contact details by a User.
      </p>

      <h2>Privacy</h2>

      <p>
        Askitall.co does its best to protect User privacy with respect to
        information not otherwise disclosed by User in questions submitted to
        Askitall.co. In connection with the submission of Free Questions and
        Paid Consultations, Company only require and Company encourage User to
        only provide the minimum amount of personal identifying information
        necessary to process a question. All of User personal information
        provided to Askitall.co, including email addresses, is concealed from
        all other User of Askitall.co and is never published as part of the
        Askitall.co public database. Only the Askitall.co administrators have
        access for purposes of Website administration only to this information.
        Askitall.co will not disclose this information to third parties without
        User permission except in the following circumstances:
      </p>

      <ul>
        <li>To protect ourselves from liability or</li>
        <li>
          To respond to legal process or to otherwise comply with the law.
        </li>
      </ul>

      <p>
        With respect to certain information supplied by User as explained
        further below, there will be a need for disclosure to Advisors /
        Professionals when User have booked a paid consultation. Whether other
        information submitted to Askitall.co by User, such as the contents of
        the Question itself, will be publicly disclosed will vary as between
        Free Questions and Paid Consultations:
      </p>

      <h2>Ownership of Content</h2>

      <p>
        By submitting a Free Question to Askitall.co, User understand and agree
        that User question and any answer provided by Advisor / Professional
        will become part of the Askitall.co public database and the sole
        property of Askitall.co. Thus, as to all Free Questions and any answers,
        User agree that without any compensation to User, Askitall.co will own
        all rights to the submitted questions and answers (including any
        intellectual property and moral rights) and will have the sole
        unrestricted right to use, reproduce, edit, modify, sell, adapt,
        publish, translate, create derivative works from, distribute,
        communicate to the public, perform, and display the submitted content
        (in whole or in part) worldwide and/or to incorporate it in other works
        in any form, media, or technology now known or later developed for the
        full term of any rights that may exist in the content.
      </p>

      <h2>Off-Boarding</h2>

      <p>
        Breach of this clause may lead to immediate suspension or termination of
        the Professional's Askitall account with or without any prior notice.
      </p>

      <h2>Entire Agreement</h2>

      <p>
        These Terms & Conditions, together with the privacy policy which shall
        be deemed to be a part hereof and incorporated herein by reference,
        represent the complete agreement between User and Askitall.co concerning
        the subject matter hereof and it replaces all prior oral or written
        communications (if any) concerning such subject matter.
      </p>

      <p>
        The Terms & Conditions shall commence upon the date User use the Website
        in any form. This Terms & Conditions and the licenses granted hereunder
        shall be terminated without notice in the event User (or any authorized
        person using User account) fail to comply with the Terms and Conditions
        of this Terms & Conditions or the rules for use of the Askitall.co
        service promulgated by Askitall.co from time to time. Company may also
        cancel or suspend User registration for any other reason, including
        inactivity for an extended period, but will attempt to notify User in
        advance of such cancellation or suspension. Upon any termination, User
        will destroy all copies of Askitall.co materials in User possession and
        cease any access to or use of the Website. Upon this suspension or
        termination, Askitall.co may delete information, files, and other
        previously available content to User, including but not limited to
        information provided by User. Askitall.co shall not be liable to User or
        any third party for any termination of User access to the Website and/or
        the Services.
      </p>

      <p>
        The provisions of this Terms & Conditions are severable and in the event
        any provision hereof is determined to be invalid or unenforceable, such
        invalidity or unenforceability shall not affect the validity or
        enforceability of the remaining provisions, but such a provision shall
        be reformed if reasonably possible only to the extent necessary to make
        it enforceable.
      </p>

      <p>
        The failure of Askitall.co to exercise or enforce any right or provision
        of these Terms & Conditions shall not constitute a waiver of such right
        or provision. The failure of either party to exercise in any respect any
        right provided for herein shall not be deemed a waiver of any further
        rights hereunder.
      </p>

      <p>
        User affirmative act of using this Website and/or registering for the
        Website or the Services constitutes User electronic signature to these
        Terms & Conditions, Privacy Policy that shall be deemed to be a part
        hereof and incorporated herein by reference, and User consent to enter
        into agreements with us electronically.
      </p>

      <p>
        I, the User/User, understand the risks and benefits of the online
        consultation. I acknowledge and agree to assume the risk of the
        above-mentioned limitations pursuant to the use of Askitall.co. I
        further understand and agree that no warranty or guarantee has been made
        to me concerning any consultation on a particular problem, result,
        solution of my query/problem.
      </p>

      <p>
        I understand that the laws that protect privacy and the confidentiality
        of personal and/or Professional Services’ information also apply to
        Askitall.co and that my information will be only used as defined by the
        Company`s Terms & Conditions and Privacy Statement.
      </p>

      <p>
        I understand that the consultation through Askitall.co may involve
        electronic communication of my personal information to other Advisors
        who may be located in other areas, including out of state.
      </p>

      <p>
        If User do not agree to Company`s Terms & Conditions, Company`s Privacy
        Policy, Company, or Company`s Payment Cancellation & Refund policy,
        kindly User REFRAIN FROM USING this Website.
      </p>

      <h2>Grievance officer</h2>

      <p>
        Name: [Name]
        <br />
        Mobile: [Mobile]
        <br />
        Email: care@Askitall.co
        <br />
        CIN: [CIN]
      </p>
      <div className="terms-content poppins-medium">{props.children}</div>
    </div>
  );
}

export default TermsContent;
