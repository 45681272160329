import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const userTestimonials = [
  {
    avatar: { props: { src: "https://mui.com/static/images/avatar/1.jpg" } },
    name: "Aditya Sharma",
    occupation: "Doctor",
    testimonial:
      "As a doctor, confidentiality is paramount. Ask It All's secure consultation process ensured my privacy was protected throughout. The platform's detailed profiles and reviews helped me browse the right expert for my problem. The advice I received was not only insightful but directly addressed my specific concerns, relieving my stress. I highly recommend Ask It All to my friends and colleagues who are interested in seeking reliable advice",
  },
  {
    avatar: { props: { src: "https://mui.com/static/images/avatar/2.jpg" } },
    name: "Saurabh Gupta",
    occupation: "Civil Engineer",
    testimonial:
      "Planning for retirement used to be a major source of stress for me. As my retirement age approaches, I often found myself constantly worrying about whether my savings would last and how I would manage my expenses. The financial aspects seemed confusing and overwhelming at the same time. That's when I discovered Ask It All",
  },
  {
    avatar: { props: { src: "https://mui.com/static/images/avatar/3.jpg" } },
    name: "Navya Dua",
    occupation: "IT Professional",
    testimonial:
      "I was just echoing the 'Mutual Funds Sahi Hai' mantra, but now I actually understand why! Thanks to Ask It All! Honestly, if you're feeling overwhelmed by investing, I highly recommend checking out Ask It All. They can help you pave a way forward in the world of mutual funds and make smart financial decisions for your future",
  },
  // {
  //   avatar: { props: { src: "https://mui.com/static/images/avatar/4.jpg" } },
  //   name: "Julia Stewart",
  //   occupation: "Senior Engineer",
  //   testimonial:
  //     "I appreciate the attention to detail in the design of this product. The small touches make a big difference, and it's evident that the ",
  // },
  // {
  //   avatar: { props: { src: "https://mui.com/static/images/avatar/5.jpg" } },
  //   name: "John Smith",
  //   occupation: "Product Designer",
  //   testimonial:
  //     "I've tried other similar products, but this one stands out for its innovative features. It's clear that the makers put a lot of thought into creating ",
  // },
  // {
  //   avatar: { props: { src: "https://mui.com/static/images/avatar/6.jpg" } },
  //   name: "Daniel Wolf",
  //   occupation: "CDO",
  //   testimonial:
  //     "The quality of this product exceeded my expectations. It's durable, well-designed, and built to last. Definitely worth the investment!",
  // },
];

const whiteLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg",
];

const darkLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg",
];

function TestimonialCard({ testimonial, logo }) {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between">
      <p className="text-gray-600 mb-4">{testimonial.testimonial}</p>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {/* <img src={testimonial.avatar.props.src} alt={testimonial.name} className="w-10 h-10 rounded-full mr-3" /> */}
          <div>
            <p className="font-semibold">{testimonial.name}</p>
            <p className="text-sm text-gray-500">{testimonial.occupation}</p>
          </div>
        </div>
        <img src={logo} alt="Company logo" className="w-16 opacity-30" />
      </div>
    </div>
  );
}

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const logos = darkLogos; // You can change this to whiteLogos based on your theme logic

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === userTestimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? userTestimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="container mx-auto px-4 py-12 md:py-16">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-4">Testimonials</h2>
        <p className="text-gray-600">
          See what our customers love about our products. Discover how we excel
          in efficiency, durability, and satisfaction. Join us for quality,
          innovation, and reliable support.
        </p>
      </div>

      {/* Mobile Carousel */}
      <div className="md:hidden">
        <div className="relative">
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {userTestimonials.map((testimonial, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <TestimonialCard
                    testimonial={testimonial}
                    logo={logos[index]}
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={prevTestimonial}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          >
            <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
          </button>
          <button
            onClick={nextTestimonial}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          >
            <ChevronRightIcon className="h-6 w-6 text-gray-600" />
          </button>
        </div>
      </div>

      {/* Desktop Grid */}
      <div className="hidden md:grid grid-cols-3 gap-6">
        {userTestimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            testimonial={testimonial}
            logo={logos[index]}
          />
        ))}
      </div>
    </div>
  );
}
