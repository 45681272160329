import React from "react";
import PolicyFormatter from "../../components/PolicyFormatter";

import Footer from "../Website/Footer";
import NavbarWebsite1 from "../../components/Navbar/NavbarWebsite1";

const PrivacyPolicy = () => {
 const  policyText = `
  This privacy policy is published in compliance with- 
 Section 43 (A) of the Information Technology Act ,2000
  Regulation 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal information) Rules, 2011
 Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rule 2011

 User privacy is very important to us. Company respect User's privacy and believe that Company should clearly and transparently disclose Company privacy practices to user. This privacy policy (the "Privacy Policy") explains our policies regarding collection, use and disclosure of user personal information, personally identifiable information and other data collected from the user when the user use our Services. This Privacy Policy does not apply to any third-party websites or applications. Please note that the user shall visit any third-party sites and apps at their own risk upon conditions laid down in their privacy policies, if any.

 Company may change, modify, amend, terminate or replace this Privacy Policy at any time at our discretion. If Company change, modify, amend or terminate this Privacy Policy, such modifications will become effective immediately upon posting to the Website and/or App. Company will notify user of material changes in advance by email or by notice when user log on to the Website or the App.

 Company will not use or share user's information with anyone except as described in this Privacy Policy. Company uses user's information for providing and improving the Services. By using the Services, user agree to the collection and use of information in accordance with this Privacy Policy.

 User agree to periodically review the current version of the Privacy Policy, as posted on the Website or the App. If user do not agree with the terms of this Privacy Policy, please do not use the Services.

 This Privacy Policy is to be read with, and is an integral part of, our Terms of Use available at https://www.askitall.co/consumer-terms-of-use Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Use.

 COLLECTION OF INFORMATION

 Personal Information - While using our Services, Company may ask user's to provide us with certain personally identifiable information ("PII") that can be used to contact or identify user. PII includes, but is not limited to, user's name, phone number, postal address, email address and other information that by itself, or in conjunction with other information, can be used to specifically identify user. However, PII does not include aggregated information that, by itself, does not permit identification.

 Sensitive Information- 'Sensitive Personal Data or Information' means Personal Information of any individual relating to password; financial information such as bank account or credit card or debit card or other payment instrument details; physical, physiological and mental health condition; sexual orientation; medical records and history; biometric information; any detail relating to the above as provided to or received by ASKITALL for processing or storage. However, any data/ information relating to an individual that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law shall not qualify as Sensitive Personal Data or Information.

 Be advised, in case user's question or consultation contains matters of high security risk or there is a high privacy concern (like matters of Constitution, matters related to judiciary, matters related to police or law enforcement) Company suggest user to use alternate services.

 Information that Company collect when user use our Services includes:

 Information provided by user
When user sign up and register with us through the Website and/or App, Company asks user for certain information. This will include user's name, email address, mobile number, telephone number, details of company name & registration, company address, and details of legal mandates user wish to use our Services for.

Further, Company asks user for certain information pertaining to each legal mandate that user may wish to list on our Platform. This includes information such as user's location, gender, other details required for the service user may like to buy, which is critical for the provision of our Services ("Mandate Information")

 Log Data
With the help of third parties like Google and Facebook, Company collects information that user's browser or mobile device sends to them whenever user visit our Services ("Log Data"). This Log Data includes information such as user's computer's Internet Protocol ("IP") address, geolocation and geospatial data, browser type and browser version. In case user are accessing the App, Company may collect certain mobile device information such as device ID, model number, operating system, global positioning data, application, and other related information. Company also collect data on the pages of our Services that user visit, the time and date of user's visit, the time spent on those pages, default language and user preferences, and other similar usage related statistics. Company may assign each user of the Services unique identifiers to help keep track of future visits.


 Payment information
Our Services require user to make payments on the Platform. When user is making any payments, Company collects billing details from user such as user's name, name of company, billing address, contact number, payment method and IP address, and then redirect user to a secure payment service provider platform operated by our payments partner(s) Cashfree etc. and other payment partners which may be added time to time. Company do not store any payment related information such as credit/debit card details and/or bank details.

 Advertising, Use of Cookies, and Other Tracking Technologies (Cookie Policy)

Company advertises in a number of ways, including online through managed social media presences, and on other unaffiliated sites and mobile applications. To understand how our advertising campaigns are performing, Company may collect certain information via our Sites through our advertising service providers. Company or our vendors use several common online tracking tools to collect information about our users, such as browser cookies, web beacons, and other similar technologies. The information Company collect includes IP addresses, the number of page visits, pages viewed via our Sites, search engine referrals, browsing activities over time and across other websites following user's visit to one of our Sites or applications, and responses to advertisements and promotions on the websites and applications where Company advertise. Company do not permit third parties to advertise on our Site.
Company also uses certain information to:
 Identify new visitors to our Sites;
 Recognize returning visitors;
 Advertise on other websites and mobile applications not affiliated with us;
 Analyze the effectiveness of our advertisements;
 Better understand our audience, customers, or other Site visitors; and
 Determine whether user might be interested in new products or services.

 Controlling Our Tracking Tools.

User's browser may give user the ability to control cookies. How user do so, however, depends on user's browser and the type of cookie. Certain browsers can be set to reject all browser cookies. If user configure user computer to block all cookies, user may disrupt certain web page features, and limit the functionality Company can provide when user visit or use our Sites. If user block or delete cookies, not all of the tracking that Company have described in this policy will stop. Some browsers have a "Do Not Track" feature that lets user tell websites that user do not want to have user's online activities tracked. These browser features are still not uniform, so Company are not currently set up to respond to those signals.

 Controlling Online Interest-Based Ads.
Company sometimes works with online advertising vendors to provide user with relevant and useful ads. This may include ads served on other companies' websites. These ads may be based on information collected by us or third parties. For example, user's geographical location may be used to target an ad for people in user's area. These ads may also be based on user's activities on our Sites or on third party websites.
 Information received from others
Company may combine information about user that Company already has with information Company obtain from other sources, to provide user a better end user experience or to improve the quality of our Services. Examples of information Company may receive from other sources includes details of the Mandate Information available to us, customer support or enrolment information, page view, search term and search result information, and credit or identity information which Company may use to detect fraud.
Please note that Company use google analytics and similar kind of tools for the purpose of analytics and measurement of the Services. For example, Company may use the data gathered from user's usage of the Services by Facebook, twitter etc. to design new products or services, or to make our existing offerings under the Services more user friendly.

 Apart from the information listed above and the Mandate Information made available to us while providing our Services, Company do not collect any other sensitive information such as financial data, political views of any individuals, health-related information or sexual orientation of any individuals, information related to race, caste or language (Collectively, "Sensitive Personal Information"), unless required by the provisions of any applicable law.
 
 USE OF INFORMATION

Company uses user's PII and other information collected from user for the following purposes:
 Registration of the user on the website. 
 Processing User's order or request and provision of services.
 Completing transaction with users effectively.
 To provide user with a customized and optimized experience with our Services;
 To provide user with the functionalities of the Website, App and Services in a proper manner;
 To provide better usability, troubleshooting and site maintenance.
 To track user's usage of our Services and customize the Services to best suit user's requirements;
 To send user communications, as per user's notification settings, on any updates or developments pertaining to the Services or user's use of the Services;
 To improve the security of the Services;
 To protect our rights and property and the rights and property of our other users;
 To provide user with information about new products or services launched by us or our partners;
 To back up our systems to ensure preparedness for disaster recovery;
 To provide user with customer support services, whether through email, phone, push     notifications or messages within the Services;
 To provide user with technical service and support, including updates;
 To combine user's PII or other information with information obtained from other users on the Platform, and publish summaries of aggregate data online or offline;
 To provide information to third-parties performing services in support of our delivery of the Services to user;
 To communicate promotions and other offers, and for the purposes of advertisements;
 To improve the quality, features and functionality of the Services, including measurement of service usage and efficiency of algorithms;
 For the purposes of auditing, research and analysis to ensure the technical stability of the Platform;
 To prevent and detect any fraud or other illegal activity on the Platform, and to prevent violations of our Terms of Use;
 Investigating, enforcing, resolving disputes and applying our Terms of Use and Privacy Policy, either ourselves or through third party service providers.
 To respond to legal requirements, exercise our legal rights and defend against any legal claims, to protect our interests or those of any third parties;
 To facilitate payment processes and verify payment information;
 As may be necessary to enforce the terms of this Privacy policy and our Terms of Service.

 STORAGE OF INFORMATION

 All data storage of user's PII or other information collected from user may be stored and processed on computers located at the cloud servers provided by the GO DADDY, AWS, GCP or AZURE Web Service. Through user's use of the Services, user unequivocally consent to the processing and storage of user's information.

 Company store some of user's information on user's device for subsequent retrieval and utilization. Company takes commercially reasonable care to encrypt this information on user's device with passwords or other secrets supplied by user. Such information stored on user's personal device is subject to the security offered by user's device, browser and other components. User understand and agree that Company cannot be held responsible for any compromise of access to user's personal device, for loss of information, theft or for any exploits or logic attacks on user's device through malware or other software or hardware that is outside the control of our Services.

 Although the security of user's PII and other information is important to us, do remember that no method of electronic storage, or method of transmission over the internet is 100% secure. While Company strives to use commercially acceptable and standard means to protect user's information, including internal reviews of our data collection, storage and processing practices and security measures, Company cannot guarantee absolute security in this regard. User provide user's information and PII to us at user's own risk.

 DISCLOSURE AND SHARING OF INFORMATION

 Company may employ third parties to facilitate the Services, to provide the Services on our behalf, to help us analyze how the Services are used, or to perform Service-related services. Company may share user's information with third-parties in the following circumstances:
 Company have obtained user's consent, including when user choose to register a user account, use the Services, and otherwise share user's information with us, other users on the Platform and/or other third-parties;
 If sharing or disclosure of user's PII or other information is necessary to provide user with the Services;
 If Company have been purchased by a third-party;
 If sharing or disclosure of user's PII or other information with our associate entities, including any related companies, successors, assigns, licensees, affiliates or business partners becomes necessary. Company requires that these entities process user's PII or other information solely as per our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures;
 If user's PII or other information is demanded by a court order or by government institution and authorities, if required any law for the time being in force India.;
 If sharing or disclosure of user's PII or other information is needed to help prevent against fraud or the violation of any applicable law, statute, regulation, ordinance, or treaty;
 If sharing or disclosure of user's PII or other information is needed to protect our employees, independent contractors, officers, members or other users; and
 If otherwise permitted to do so by this Privacy Policy or by provisions of applicable law.

 The following categories of recipients will most likely receive user's information to enable us to provide the Services to user:
 Third Party Data Center Services and Cloud provider such as Go daddy Web Service, AWS, GCP and Azure;
 Third Party Simple Mail Transfer Protocol (SMTP) Services such as gmail.com;
 Facebook, Instagram, for direct marketing;
 Third party Service Providers such as Paytm, Cashfree etc.

 All financial information collected on the Platform is used solely to enable payments as consideration for the Services. No financial information collected on the Platform is used for marketing or promotional purposes. Remember that if user provide any PII directly to a third party, such as through a link on the Website or the App, the processing is based on their standards and policies, and Company are not responsible for such terms and conditions.

 When any PII or other information pertaining to the users is shared with third-parties, such as advertisers, licensees and content distributors, Company shall ensure that such information shall be non-private, aggregated or anonymized so that it will not identify user personally. User understand that Company may further distribute user's information to a wider audience through third-party sites and services, as long such distribution is consistent with this clause and other provisions contained in this Privacy Policy.

 Company may use external advertising companies to serve advertisements when user have visited our website. These companies may use anonymized information about user's visits to this and other websites in order to provide advertisements about goods and services that may be of interest to user.

 Company may share PII with our parents, subsidiaries, divisions, and affiliates or as part of any sale, merger or acquisition;

 Company will never sell user's PII to unaffiliated third-parties without user's approval at the time of collection. User agree to inform us of any changes to user's PII, and to protect the security of user's username, password and PII.

 RIGHTS WITH RESEPCT TO user's INFORMATION

At some point, user might wish to restrict the use and collection of user's personal data. User can achieve this by doing the following:

 When user is filling the forms on the website, make sure to check if there is a box which user can leave unchecked, if user don't want to disclose user's personal information.

 Request that user's information is deleted: This entitles user to request the deletion of PII, including in situations where such PII would no longer be necessary to achieve the purposes; If user have already agreed to share user's information with us, feel free to contact us via email and Company will be more than happy to change this for user 

 Request that Company correct inaccuracies relating to user's information: This right entitles user to have PII to be corrected if it is inaccurate or incomplete;

 Request that restrictions be placed on processing of PII: This right entitles user to request that Company only process PII in certain circumstances, including with consent.

 If user wish to make a request in respect of user's personal information, please contact us at  founders@askitall.co.

 If, at any time, user prefer not to receive communications from us, simply follow the unsubscribe options at the bottom of each such communication or contact us directly at founders@askitall.co. . Company will make commercially reasonable efforts to implement user's opt-out requests promptly, but user may still receive our emails for up to 14 (fourteen) working days as Company process user's request.

 In some circumstances, Company may not be able to comply with a request that user make in respect of user's personal information. For example, Company may not be able to provide a copy of user's information where it infringes on the rights of another user. Company may also be required to retain certain information that user ask us to delete for various reasons, such as where there is a legal requirement to do so.

 INFORMATION RETENTION 

 Upon receiving a deactivation request, Company will deactivate user's account and all PII and Sensitive Personal Information collected by us will be destroyed, unless a longer retention period is required by law or for directly related legitimate business purposes. Company retains non-personally identifiable information indefinitely for the purposes of analytics, subject to such information being kept separate from other information that may constitute PII or that may, in combination with such non-personally identifiable information, render the information personally identifiable.

 Company collects and retain certain information that is required for our detection, investigation and prevention of fraud, cheating and other violations of our Terms of Use and applicable laws ("Breach(es)"). This data is used only for the purposes of detection, investigation, prevention and, where applicable, acting on such Breaches and stored only for the minimum amount of time needed for the purpose of dealing with such Breaches. If the data indicates that such a Breach has occurred, Company will further store the data for the establishment, exercise or defense of legal claims, until a legal case related to it has been resolved, or such legal claim no longer exists due to the expiry of relevant limitation periods. Please note that the specific data stored for dealing with Breaches may not be disclosed to user if such disclosure will compromise our legal rights as well as the mechanism through which Company detect, investigate and prevent such Breaches.

 Change in Information
User are responsible for maintaining the accuracy of the information user submit to us, such as user's contact information provided as part of account registration. If user's personal information changes, user may correct, delete inaccuracies, or amend information by making the change on our member information page or by contacting us through founders@askitall.co. Company will in good faith make all the efforts to change our active database within a reasonable time. 
If at any time Company suspect that the information provided by the user is untrue, false, inaccurate or incomplete askitall.co at its sole discretion may discontinue services to user
 Choose to opt-out
Company provides all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from us on, behalf of our partners and from us in general after setting up account, if user want to remove user's contact information from all www.askitall.co lists, contact us through founders@askitall.co
 user's Consent

By using the website and /or by providing user's information, user consent to the collection and use of information to disclose on the website in accordance with this privacy policy including but not limited to user's concern for sharing user's information as per this privacy policy.

 Children are not allowed to use our website 

Use of www.askitall.co is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If user is a minor i.e. under the age of 18 years, user may use www.askitall.co only with the involvement of a parent or guardian. If Company learns that Company have received any information from an individual under the age of 18, Company will use that information only to respond directly to that child (or a parent or legal guardian) to inform him or her that he or she cannot use the Services and subsequently Company will delete that information from our own servers.

 CHANGES TO THIS PRIVACY POLICY

Company may update our Privacy Policy from time to time. User agree to periodically review the current version of the Privacy Policy, as posted on the Website or App. Company will notify user of material changes in advance by notice on this page when user log on to the Website or the App. Changes to this Privacy Policy are effective when they are posted on this page. If user do not agree to the change, user may discontinue the use of Services.

 Complaint and Grievance redress
Chirag Nayan Shah addresses all the discrepancies and grievance to its user with respect to processing to information within One month from the date of the receipt of the grievance.  

 Grievance Officer
In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
Name - Chirag Nayan Shah
Email - founders@askitall.co
Phone - +91 - 9619408885
Address - 25/ 26 Lohana Bhuvan, Paliram road, Andheri(W), Mumbai - 400058
Time - 12:00 pm - 2:00 pm

 CONTACT US
If user have any questions about this Privacy Policy, please contact us at 
Email 

 Further Acknowledgement
             User hereby acknowledge and agree that this Privacy Policy:
 Is clear and easily accessible and provide statements of www.askitall.co policies and practices with respective to the Information;
 Provides for the various types of personal or sensitive personal data of information to be collected;
 Provides for the purposes of collection and usage of the Information;
 Provides for disclosure of Information; and
 Provides for reasonable security practices and procedures.

If user do not agree to terms of our Privacy Policy Company kindly request user to not use this site.
Be advised, in case user's question or consultation contains matters of high security risk or there is a high privacy concern (like matters of Constitution, matters related to judiciary, matters related to police or law enforcement) Company suggest user to use alternate services.


  `;
  return (
    <div className="max-w-7xl w-full mx-auto">
{/* <Navbar/>000 */}
<NavbarWebsite1/>
       <h1 className="text-xl px-2 lg:text-2xl  font-medium my-6">Askitall Cancellation & Refund Policy</h1>
      <PolicyFormatter policyText={policyText} />
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
