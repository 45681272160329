import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [isResendOtp, setIsResendOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOtpVerificationMode, setIsOtpVerificationMode] = useState(false);
  const handleSendOtp = async () => {
    if (!/^\d{10}$/.test(phoneNumber)) {
      setPhoneError("Please enter a 10-digit number");
      return;
    }
    setPhoneError("");
    setErrorMessage("");

    try {
      const apiUrl = isResendOtp
        ? "https://api.askitall.co/v1/auth/resend-otp"
        : "https://api.askitall.co/v1/auth/send-otp";

      let requestBody = {
        phoneNumber,
        countryCode,
      };

      if (!isResendOtp) {
        requestBody = {
          ...requestBody,
          email,
          password,
        };
      }

      const response = await axios.post(apiUrl, requestBody);

      if (response.status === 200 || response.status === 201) {
        setIsOtpDisabled(false);
        setIsResendOtp(true);
        setIsOtpVerificationMode(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message || "An error occurred");
        setIsResendOtp(true);
      }
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 3000);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.askitall.co/v1/auth/verify-otp",
        {
          phoneNumber,
          countryCode,
          otp,
        }
      );

      if (response.status === 200 || response.status === 201) {
        // Store the response data in localStorage
        window.location.href = "/indexpage";
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("tokens", JSON.stringify(response.data.tokens));

        // You can add additional logic here, such as redirecting the user or showing a success message
        console.log("OTP verified successfully");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage(error.response?.data?.message || "Failed to verify OTP");
    }
  };

  return (
    <div className="bg-white py-5 text-black flex items-center lg:items-start flex-col-reverse lg:flex-row-reverse justify-center">
      <motion.div
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.25, ease: "easeInOut" }}
        className="relative z-10 w-full max-w-2xl py-4 px-8"
      >
        <Heading />
        {phoneError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <span className="block sm:inline">{phoneError}</span>
          </div>
        )}
        {errorMessage && (
          <div
            className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <Email
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          otp={otp}
          setOtp={setOtp}
          isOtpDisabled={isOtpDisabled}
          handleSendOtp={handleSendOtp}
          showTooltip={showTooltip}
          isResendOtp={isResendOtp}
          handleVerifyOtp={handleVerifyOtp}
          isOtpVerificationMode={isOtpVerificationMode}
        />
        <Terms />
      </motion.div>

      <div className="w-11/12 lg:w-full h-full  lg:max-w-xl flex flex-col justify-start items-start gap-y-4 my-5">
        <div className="p-4 bg-[#EB5757] rounded-lg flex flex-col justify-start items-start gap-y-4">
          <p className="text-white lg:text-sm">Ask It all</p>
          <p className="text-white lg:text-sm">
            Embark on a journey of knowledge with Askitall! Sign up now to
            connect with expert mentors, fuel your curiosity, and elevate your
            learning experience.
          </p>
        </div>
      
      </div>
    </div>
  );
};

const Heading = () => (
  <div>
    <div className="flex items-center gap-x-1">
      <img className="h-6 w-6 text-black" src="/logo.svg" alt="Logo" />
      <p className="lg:text-2xl text-xl font-semibold">Askitall!</p>
    </div>
    <div className="mb-9 mt-6 space-y-1.5">
      <h1 className="text-2xl font-semibold">Sign up to your account</h1>
      <p className="text-zinc-400">
        Have an account &nbsp;
        <a href="/login" className="text-[#EB5757]">
          Login.
        </a>
      </p>
    </div>
  </div>
);

const Or = () => {
  return (
    <div className="my-6 flex items-center gap-3">
      <div className="h-[1px] w-full bg-zinc-700" />
      <span className="text-zinc-400">OR</span>
      <div className="h-[1px] w-full bg-zinc-700" />
    </div>
  );
};

const Email = ({
  email,
  setEmail,
  password,
  setPassword,
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  otp,
  setOtp,
  isOtpDisabled,
  handleSendOtp,
  showTooltip,
  isResendOtp,
  isOtpVerificationMode,
  handleVerifyOtp
}) => {
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="mb-3">
        <label htmlFor="email-input" className="mb-1.5 block text-zinc-400">
          Email
        </label>
        <input
          id="email-input"
          type="email"
          placeholder="your.email@provider.com"
          className="w-full rounded-md border bg-[#F7F7F7] px-3 py-2 placeholder-zinc-500 ring-1 ring-transparent transition-shadow"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <div className="mb-1.5 flex items-end justify-between">
          <label htmlFor="password-input" className="block text-zinc-400">
            Password
          </label>
        </div>
        <input
          id="password-input"
          type="password"
          placeholder="••••••••••••"
          className="w-full rounded-md border bg-[#F7F7F7] px-3 py-2 placeholder-zinc-500 ring-1 ring-transparent transition-shadow"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 w-full  lg:grid-cols-3 gap-x-2">
        <div className="mb-3">
          <label htmlFor="phone-input" className="mb-1.5 block text-zinc-400">
            Contact Number
          </label>
          <div className="flex w-full">
            <input
              id="phone-input"
              type="tel"
              placeholder="1234567890"
              className="w-full rounded-r-md border bg-[#F7F7F7] px-3 py-2 placeholder-zinc-500 ring-1 ring-transparent transition-shadow"
              value={phoneNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                setPhoneNumber(value.slice(0, 10));
              }}
            />
          </div>
        </div>
        <div className="mb-6 relative">
          <div className="mb-1.5 flex items-end justify-between">
            <label htmlFor="otp-input" className="block text-zinc-400">
              OTP
            </label>
          </div>
          <input
            id="otp-input"
            type="text"
            placeholder="••••"
            className="w-full rounded-md border bg-[#F7F7F7] px-3 py-2 placeholder-zinc-500 ring-1 ring-transparent transition-shadow"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            disabled={isOtpDisabled}
          />
          {/* {showTooltip && (
            <div className="absolute bottom-full left-0 top-0 mb-2 bg-red-500 text-white text-xs rounded py-1 px-2">
              Server is busy. Please try again later.
            </div>
          )} */}
        </div>
        <button
          type="button"
          className="w-full text-white h-11 my-auto rounded-md bg-[#EB5757]"
          onClick={handleSendOtp}
        >
          {isResendOtp ? "Resend OTP" : "Send OTP"}
        </button>
      </div>
      <SplashButton 
        type="button" 
        className="w-full mt-4"
        onClick={isOtpVerificationMode ? handleVerifyOtp : handleSendOtp}
      >
        {isOtpVerificationMode ? "Verify OTP" : "Sign up"}
      </SplashButton>
    </form>
  );
};

const Terms = () => (
  <p className="mt-9 text-xs text-zinc-400">
  By signing in, you agree to our{" "}
  <a target="_blank" href="/termsandconditions" className="text-[#EB5757]">
    Terms & Conditions
  </a>{" "}
  and{" "}
  <a target="_blank" href="/refundPolicy" className="text-[#EB5757]">
    Refund Policy.
  </a>
</p>
);

const SplashButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        "rounded-md bg-gradient-to-br from-[#EB5757] to-[#EB5757] px-4 py-2 text-lg text-zinc-50   transition-all hover:scale-[1.02] hover:ring-transparent active:scale-[0.98] ",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

const BubbleButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={twMerge(
        `
        relative z-0 flex items-center gap-2 overflow-hidden whitespace-nowrap rounded-md shadow-inner bg-[#F7F7F7]
        px-3 py-1.5
        text-black transition-all duration-500 delay-200 ease-in-out
        

        hover:scale-105  hover:text-zinc-900
        hover:before:translate-y-[0%]
        active:scale-100`,
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default SignupPage;
