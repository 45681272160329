import React from "react";import { useNavigate } from "react-router-dom";

import Footer from "./Footer";
import Statssection from "../../components/StatsSection/Statssection";
import Company from "../../components/Company/Company";
import Hero from "../../components/Hero/Hero";
import Features from "../../components/Features/Features";
import Navbar from "../../components/navigation";
import NavbarWebsite from "../../components/Navbar/NavbarWebsite1";
import Expertise from "../../components/Expertise/Expertise";
import Testimonials from "../../components/Testimonials/Testimonials";
import { Faq } from "../../components/Faq/Faq";

function Website() {
  const Navigate = useNavigate();
  const data = [
    {
      userName: "Jay Vaughun",
      description: "LOren ipsum",
      questionsAnswered: 16,
      rating: 2,
      title: "Product Designer",
    },
    {
      userName: "Jay Vaughun",
      description: "LOren ipsum",
      questionsAnswered: 16,
      rating: 2,
      title: "Product Designer",
    },
    {
      userName: "Jay Vaughun",
      description: "LOren ipsum",
      questionsAnswered: 16,
      rating: 2,
      title: "Product Designer",
    },
    {
      userName: "Jay Vaughun",
      description: "LOren ipsum",
      questionsAnswered: 16,
      rating: 2,
      title: "Product Designer",
    },
  ];
  return (
    <div className="">
<NavbarWebsite/>
     <Hero/>
     <Company/>
     <Expertise/>

     <Features/>
<Testimonials/>
<Statssection/>
<Faq/>
     <Footer/>
    </div>
  );
}

export default Website;