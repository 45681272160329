import React from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  // Navigate,
} from "react-router-dom";
// import { useDispatch } from "react-redux";
import Homepage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import TermsPage from "./pages/TermsPage";

import Payment from "./pages/HomePage/Payment";
import Reports from "./pages/HomePage/Reports";
import History from "./pages/HomePage/History";

import AddCredit from "./pages/AddCredit/index";
// import AdvisorPage from "./pages/AdvisorPage/AdvisorPage";
// import BookAdvisorPage from "./pages/AdvisorPage/BookAdvisorPage";
import PopUp from "./pages/popUp/popUp";

// import { setUser } from "./redux/features/user/userSlice";
// import { login } from "./redux/features/auth/authSlice";
// import { makeApiCall } from "./api/config";
// import { urls } from "./api/apiUrl";
// import PolicyPage from "./pages/PrivacyPolicy";
import RefundPolicyPage from "./pages/RefundPolicy";
import Website from "./pages/Website";
import Profile from "./pages/HomePage/Profile/Profile";
import Bookings from "./pages/Bookings/Bookings";
import Advisor from "./pages/Advisor/Advisor";
import TandC from "./components/TandC";
import RefundPolicy from "./components/RefundPolicy";
import Disclaimer from "./components/Disclaimer";
import Contact from "./components/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
// import { setMentor } from "./redux/features/mentor/mentorSlice";

function AppRoutes() {
  // const dispatch = useDispatch();

  // const fetchUser = async () => {
  //   try {
  //     const userType = localStorage.getItem("userType");
  //     if (userType === "Mentee") {
  //       const userId = localStorage.getItem("userId");
  //       if (userId) {
  //         const response = await makeApiCall(
  //           "GET",
  //           `${urls.getSingleUser}/${userId}`,
  //           null
  //         );
  //         if (response.user) {
  //           dispatch(setUser(response.user));
  //           dispatch(login());
  //         }
  //       }
  //     } else {
  //       const mentorId = localStorage.getItem("mentorId");
  //       const response = await makeApiCall(
  //         "GET",
  //         `${urls.getAllMentors}/${mentorId}`,
  //         null
  //       );
  //       if (response.success) {
  //         dispatch(setMentor(response.mentor));
  //         dispatch(login());
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch user data", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchUser();
  // }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Website />} />
        <Route exact path="/contact" element={<Contact />} />

        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/disclaimer" element={<Disclaimer />} />
        <Route exact path="/termsandconditions" element={<TandC />} />
        <Route exact path="/refundPolicy" element={<RefundPolicy />} />
 
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/website" element={<Website />} />
        <Route exact path="/signup" element={<SignupPage />} />
        <Route exact path="/pop/:consultantId" element={<PopUp />} />
        <Route exact path="/terms" element={<TermsPage />} />
        <Route exact path="/refundpolicy" element={<RefundPolicyPage />} />
        {/* <Route exact path="/privacypolicy" element={<PolicyPage />} /> */}
        {/* <Route exact path="/advisors" element={<BookAdvisorPage />} /> */}
        <Route exact path="/indexpage" element={<Profile />} />
        <Route exact path="/bookings" element={<Bookings />} />
        <Route exact path="/advisor" element={<Advisor />} />
        <Route
          exact
          path="/profile"
          element={
            <Homepage>
              <Profile />
            </Homepage>
          }
        /> 
        {/* <Route
          path="/advisor_profile"
          element={
            <Homepage>
              <AdvisorPage />
            </Homepage>
          }
        /> */}

        <Route
          exact
          path="/payment"
          element={
            <Homepage>
              <Payment />
            </Homepage>
          }
        />
        <Route
          exact
          path="/history"
          element={
            <Homepage>
              <History />
            </Homepage>
          }
        />
        <Route
          exact
          path="/report"
          element={
            <Homepage>
              <Reports />
            </Homepage>
          }
        />
        <Route exact path="/credit" element={<AddCredit />} />
      </Routes>
    </Router>
  );
}

// function PrivateRoute({ children }) {
//   const token = localStorage.getItem("token");
//   return token ? children : <Navigate to="/website" />;
// }

export default AppRoutes;
