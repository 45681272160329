import React from "react";

const ProfilePage = ({ user }) => {
  const location = window.location.pathname;
  console.log(location);
  return (
    <div className="w-11/12 lg:w-[30%] sticky h-full lg:h-[95vh] mx-auto bg-white shadow-lg rounded-md overflow-auto hideScrollbar lg:overflow-hidden">
      <div className="p-4">
        <div className={`flex items-center w-full justify-between text-black ${location === "/bookings" ? "lg:flex hidden" :""} `}>
          <p className="text-xl font-semibold ">Your Profile</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            className="mb-7"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-ellipsis-vertical"
          >
            <circle cx="12" cy="12" r="1" />
            <circle cx="12" cy="5" r="1" />
            <circle cx="12" cy="19" r="1" />
          </svg>
        </div>

        <div className={`  flex flex-row lg:flex-col items-center my-3`}>
          <div className="w-24 lg:w-20 h-24 lg:h-20 rounded-full overflow-hidden border-4 border-red-500 mb-2">
            <img
              src="https://flowbite.com/docs/images/people/profile-picture-3.jpg"
              alt="Profile"
              className="p-2 rounded-full w-full h-full object-cover"
            />
          </div>
          <div className="flex flex-col lg:items-center items-start ml-4">
          <h2 className="text-lg lg:text-sm font-semibold">{user?.email}</h2>
          <p className="text-sm font-semibold text-[#EB5757] ">100 Credits</p>
          <p className="text-xs font-medium text-[#7E7E7E]">Member</p>
          </div>
        </div>
        <div className={`  flex flex-col justify-between py-2`}>
          <div className={`${location === "/bookings" ? "lg:block hidden" :""} mb-8`}>
            <div className="w-full mb-4 justify-between flex items-center">
              <p className="text-sm font-semibold ">Education</p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="11.5"
                  stroke="#9E9E9E"
                />
                <path
                  d="M9 12H15"
                  stroke="#9E9E9E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 15V9"
                  stroke="#9E9E9E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="space-y-4 lg:space-y-2">
              <EducationItem
                school="Mumbai University"
                degree="Bachelor's in Psychology"
              />
              <hr className="border border-[#D8D8D8] w-full" />
              <EducationItem
                school="Harvard University"
                degree="Bachelor's in Psychology"
              />
            </div>
          </div>

          <div className={``}>
            <div className={`${location === "/bookings" ? "lg:block hidden" :""} mb-4`}>
              <div className="w-full mb-0 justify-between flex items-center">
                <p className="text-sm font-semibold ">Work Experience</p>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#9E9E9E"
                  />
                  <path
                    d="M9 12H15"
                    stroke="#9E9E9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 15V9"
                    stroke="#9E9E9E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className={` ${location === "/bookings" ? "lg:block hidden" :""} space-y-4 lg:space-y-2`}>
              <WorkExperienceItem
                company="Deloitte"
                position="Engineer (Current Job)"
              />
                            <p className="text-[10px] font-normal">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit.
              </p>
              <hr className="border-[0.5px] border-[#D8D8D8] w-full" />
              <WorkExperienceItem
                company="PWC"
                position="Software Developer (2018 to 2022)"
              />
            </div>

            <div className="pt-6 lg:hidden flex flex-col justify-start gap-y-4">
              <p className="text-sm font-semibold " >Upcoming Sessions</p>
              <div className="flex flex-row justify-start items-start gap-x-4">
<div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F6F5FB] rounded-xl">
<p className="font-medium text-sm text-black max-w-xs w-full">Prashant Kumar <br/> Singh</p>
<p className="font-normal text-sm text-black">Understanding Concept of React</p>
</div>

<div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F5F9F9] rounded-xl">
<p className="font-medium text-sm text-black max-w-xs w-full">Prashant Kumar <br/> Singh</p>
<p className="font-normal text-sm text-black">Understanding Concept of React</p>
</div>
              </div>
            </div>

            <div className="pt-6  flex-col lg:hidden flex  justify-start gap-y-4">
              <p className="text-sm font-semibold " >Past Sessions</p>
              <div className="flex flex-row justify-start items-start gap-x-4">
<div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F6F5FB] rounded-xl">
<p className="font-medium text-sm text-black max-w-xs w-full">Prashant Kumar <br/> Singh</p>
<p className="font-normal text-sm text-black">Understanding Concept of React</p>
</div>

<div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F5F9F9] rounded-xl">
<p className="font-medium text-sm text-black max-w-xs w-full">Prashant Kumar <br/> Singh</p>
<p className="font-normal text-sm text-black">Understanding Concept of React</p>
</div>
              </div>
            </div>

            {location === "/bookings" && 
              <div className="pt-6  flex-col lg:hidden flex  justify-start gap-y-4">
              <p className="text-sm font-semibold " >Cancelled Sessions</p>
              <div className="flex flex-row justify-start items-start gap-x-4">
<div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F6F5FB] rounded-xl">
<p className="font-medium text-sm text-black max-w-xs w-full">Prashant Kumar <br/> Singh</p>
<p className="font-normal text-sm text-black">Understanding Concept of React</p>
</div>

<div className="flex flex-col justify-start items-start gap-y-2 p-3 bg-[#F5F9F9] rounded-xl">
<p className="font-medium text-sm text-black max-w-xs w-full">Prashant Kumar <br/> Singh</p>
<p className="font-normal text-sm text-black">Understanding Concept of React</p>
</div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const EducationItem = ({ school, degree }) => (
  <div className="flex justify-between items-center">
    <div>
      <p className="font-medium text-xs">{school}</p>
      <p className="text-xs text-gray-600">{degree}</p>
    </div>
    <span className="bg-[#EB5757] text-white text-[10px] px-3 py-1 rounded-lg">
      Delete
    </span>
  </div>
);

const WorkExperienceItem = ({ company, position }) => (
  <div className="flex justify-between items-center">
    <div>
      <p className="font-medium text-xs">{company}</p>
      <p className="text-xs text-gray-600 ">{position}</p>
    </div>
    <span className="bg-[#EB5757] text-white text-[10px] px-3 py-1 rounded-lg">
      Delete
    </span>
  </div>
);

export default ProfilePage;
