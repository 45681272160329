import React from 'react'
import NavbarWebsite from './Navbar/NavbarWebsite1'
import PolicyFormatter from './PolicyFormatter'

const RefundPolicy = () => {
  const policyText = `
Askitall strives to ensure that the services user avail through the website and Applications are to your full satisfaction and are available to User at extremely reasonable and affordable prices. However, there may arise situations when User desire to Cancel or avail a Refund.
CONTRACTUAL AGREEMENT
These Askitall payment, cancellation & refund policy (constitute a legally binding agreement between User and Askitall (Askitall Private Limited), a private limited liability company established in the India, having its. By downloading, installing or using the website & Application, or accessing or using the Services and using Askitall Credit Wallet, User:
Agree that User have read, understood, accepted and agreed to these Askitall payment, cancellation & refund policy Terms;
Agree that the representations made by User in the Terms and Conditions will apply equally in respect of Askitall Credit Wallet; and
Acknowledge and agree that any professionals, Experts or Advisors registered on Askitall platform including but not limited to Financial Experts, Financial Advisor, Share Market Expert, Equity advisor, RIA (Registered Investment Advisor), Legal Consultant, Advocate, Lawyer, Legal Advisor, Chartered Accountants, Company Secretary or any other professional as the case may be (hereinafter referred to as 'Advisor') are not employee of Askitall or any of its Affiliates;
Acknowledge that where User select Askitall Credit as a payment method for a Transaction, any payment obligation to Advisor will be borne by and settled by Askitall on behalf of user with the relevant Advisors up to and including the total balance of Askitall Credit reflected in your Account at the time of completing a Transaction;
Acknowledge that where User select Askitall Wallet Credit as a payment method, this will result in a deduction to your Askitall Wallet Credit balance (as reflected in your Account) at completion of your Transaction;
These Askitall payment, cancellation & refund policy supersede any prior terms, agreements or arrangements with User in respect of Askitall Credit Wallet. Askitall may (subject to any prevailing laws) immediately terminate these Askitall payment, cancellation & refund policy with respect to User, or generally cease offering or deny access to Askitall Credit, at any time and for any reason.
DEFINITIONS
Defined terms, including (but not limited to) “Askitall”, “Account”, “Application”, “Services”, and “Third Party Providers” not otherwise defined below will have the same meaning as provided in the “Terms and Conditions”.
Third-Party services
“Payment Method” shall denote any method provided by a third-party payment service provider for the facilitation of payment services, specifically for adding credit to the Askitall Credit Wallet. This method shall be as described and offered by the third-party payment service provider on their website, application, or platform. 
“Transaction” means the purchase of services offered by an Advisor via the Application or Website.
“Askitall Credit Wallet” means a Wallet where user deposit money for purchasing the services provided by the advisor on Askitall platform.
“Askitall Credits” means the prepaid balance which is represented electronically, denominated in ASKITALL Credit, where 1 Credit equal to 1 Indian Rupees and attributed to your Askitall Credit Wallet, and which:
Is Credited to user's Askitall Credit Wallet by Askitall upon collection or receipt of funds;
May be redeemed or applied by a User as a payment method for a Transaction whereby Askitall will satisfy and settle the payment obligation to the relevant Advisor up to the Askitall Credit balance reflected in your Account.

PAYMENT POLICY

USE OF ASKTIALL CREDIT
All fee has to be paid upfront before availing any paid service available at Askitall.co. User must have an active and valid Account to use Askitall Credit Wallet. Askitall Credit may only be applied as a payment method within the website or Application for Transactions within the Territory.
Where User have elected Askitall Credit as a payment method, your total Transaction cost will include the cost of the Transaction plus any applicable taxes, fees or charges imposed by the Advisor as set out in the General Terms. Askitall does not levy charges or fees for your Account or usage of Askitall Credit.
User may review your Transactions by logging into your Account via the Application. The Askitall Credit balance displayed in the Application will serve as conclusive evidence of your Askitall Credit balance.

LOADING ASKITALL CREDIT
User may load Askitall Credit using any one or more of the following payment methods available on the third-party platform i.e. Cashfree. 
debit card;
Credit card; or
any other payment method made available in the Website or Application of Cashfree,
User may reload your Askitall Credit balance at any time before commencement of a Service by clicking on the ‘ADD CREDIT’ tab.
By selecting a particular Payment Method, User acknowledge that User are agreeing to the terms of service of Askitall payment processing partners i.e. Cashfree. User agree that User will bear the cost or charges of any fees imposed by such payment processing partners or financial institutions in connection with your selected Payment Method. User further agree that, Askitall is not responsible for the actions of Cashfree. Kindly review Cash free’s policies before engaging in any transactions or adding funds.

Askitall may in its sole and absolute discretion choose to refuse a request to reload Askitall Credit, including (without limitation) where reloading Askitall Credit would result in the aggregate value stored in your Account exceeding any value permitted by prevailing laws and regulations. Askitall (in its sole and absolute discretion) may, at any time (including at the time of completing a Transaction), notify User, that it has imposed a maximum amount by which User may reload Askitall Credit or to inform User that User have reached the limit on the number of times User may reload Askitall Credit within a specified period. By loading Askitall Credit User acknowledge and agree that User are not depositing Credit or money with Askitall.

PAYMENT TRANSACTIONS

User acknowledge that transaction errors relating to such Transactions may result in a reversal of the transaction, fees, claims, penalty or chargeback from the financial institution or payment services provider that has issued the payment card. 
User acknowledge that the financial institution or payment services provider which issues or supports the designated Payment Method User have linked to the Application and not Askitall determines any amount reversed, returned or charged back and that ASKTIALL is bound to follow the instructions of that financial institution or payments services provider. 
User agree to authorize ASKTIALL to work with user's financial institution or payment services provider to recover any amounts owing to ASKTIALL and if necessary.
User agree that User will be responsible for resolving any disputes with the financial institution by which user have added the amount in Askitall Credit Wallet.

CANCELLATION POLICY

We understand that plans can change unexpectedly, and we strive to make these changes to the best of our abilities. However, to ensure fairness and efficient management of resources, we have established the following cancellation policy:

If the service has already commenced, the booking cannot be cancelled and no refund can be issued.
User intends to cancel a successfully placed booking before execution, they are required to do so at least four hours prior to the booking time. Any Cancellations made within four hours of the booking time will not be eligible for a refund.
User intends to cancel a successfully placed booking before execution but more than four hours prior to the booking time are eligible for a refund of the booking amount can apply for refund as per conditions of refund policy mention below.

No refund will be processed once the booking has been placed and executed, except according to the conditions of the refund policy mentioned below.

NOTE
The risk and liability of placing booking in a haste and careless manner totally lies with the User and the Company is not responsible for any refund once the processing stage has started.
Any technical delay or glitch reported in the Website during the processing of the request which includes generating reports by the service provider. The User agrees that the timelines are approximate and all essentials' steps would be taken to adhere to the timelines as displayed.
No refund shall be processed for the reason that in-correct information or data has been provided by User. The User agrees to be careful while providing any information to the Website and must re-check the information filled before clicking on “Submit”.
If there are services that the Website is unable to accept and must cancel, the Company at its sole discretion, reserves the right to refuse or cancel any services for any reason whatsoever. Some situations may result in the services being cancelled and include, without limitation, non-availability of the service, inaccuracy, error in pricing information or other problems as identified. If the User's booking is cancelled after charges being paid against the said service, the said amount paid for booking shall be refunded to User's Askitall Credit Wallet.
Any refund amount will be Credited to the user's Askitall Credit Wallet only.
REFUND POLICY

In any cases, in which the Refund Policy will apply. User can apply for a Refund under any of the following conditions:
In the event of cancellation of any paid services for which payment has been successfully made and a booking number has been generated and the services has not been concluded but where user is eligible for refund as per cancellation policy.
when there is a delay in the services offered from Askitall, beyond the time frame intimated to the User, only for factors for which Askitall is solely responsible and the services has not been concluded due to any avoidable / unavoidable reason(s)
In the event User request for consultation is accepted but post-payment due to unexpected and extenuating circumstances, User request for consultation is rejected or cancelled; User's will be given an option of opting for another request for consultation OR will be offered a complete refund, if User's so desire a refund of Credits. 
If user has recharged twice by mistake in short time due to bad internet issue or due to payment gateway issue.
If a user requests the refund of amount after successful execution of services by the advisor. Where User is not satisfied with the service due to any of the reasons mentioned below.
Voice Disturbance, background noise, either one is inaudible on while consultation, etc.
The Consulting Advisor is unable to answer fluently in the language mentioned on the consulting page.
In case of Advisor found taking longer than required time in answering user's query.
In case of Advisor found of providing inappropriate or irrelevant answer to user's query.
By Requesting for Refund(s), User also agrees to our terms and conditions and permission to access the recording of the booking for which user has requested the refund. 
In any or all of the above situations, kindly send an e-mail to: contact@Askitall.in mentioning the Booking Id and the reason for claiming Refund. Upon receiving your e-mail, the Senior Management at Askitall shall decide on whether User's request for a Refund should be processed, contingent on the reasons for such a request. Our Customer Service team will contact User within 7 business days from the date of receiving the complaint. Please note that Askitall reserves the right to take the final and binding decision with regard to requests for Refund. In case the Refund is approved by Askitall, then only the professional fees paid for the services shall be refunded. Any Government fees or Taxes paid shall not be refunded. The maximum refund amount claimable from Askitall cannot exceed the amount paid by the User to Askitall for availing the services.
It is mandatory for the User's to maintain unique Transaction ID numbers, which would be provided at the time of placing a request. This number would be required to address any problems, if any.
Any refund in any situation will take 15 working days from the date of Approval of refund request. The refund will be processed in Askitall Credit Wallet account only that was used to make the payment.
Please note if user transfer ASKITALL Credits from Askitall Credit Wallet to user's bank account, there will be a nominal deduction of transaction processing fee charged by the financial institution. In case User's Credit card/debit card/payment account has been accidentally over-charged, Askitall.co is not responsible for it, user can directly contact to CASHFREE customer case for any refund related issue.

EXPIRY AND TRANSFERABILITY OF ASKITALL CREDIT 
Askitall Credits can be transferred back to the source account of the user, subject to deduction of the transaction processing fee or charges applied by the third-party payment service provider, within a period of 14 working days. It is explicitly stated that Askitall does not impose any charges for this transfer. However, it is important to note that Askitall shall not be held responsible for any refund processing conducted by financial institutions.
Additionally, in the event that a user initiates a transfer of funds from their Askitall Credit Wallet, the corresponding amount of Credits shall be deducted from the user's Askitall Credit Wallet. It is further emphasized that Credits added to the Askitall Credits Wallet do not expire.

CUSTOMER IDENTIFICATION, FRAUD IDENTIFICATION AND ANTI-MONEY LAUNDERING
In booking to ensure your security, prevent fraud or identity theft and comply with anti-money laundering laws and regulations (as applicable), we will request information from User at the time of opening your Account to confirm your identity, to allow User to open and maintain an Account and to use Askitall Credit Wallet. We may also request User to update and verify your information from time to time. This is one way that Askitall seeks to obtain comfort that the only person who can access your Account and Askitall Credit is User. Askitall may delay, suspend or block your access to Askitall Credit until User comply with this information request or other verification steps that may be required by Askitall at any time.
Askitall may, from time to time, be required to undertake financial crime screening to comply with applicable laws or regulations, in such circumstances, User agree to assist Askitall and cooperate with any requests for information.
Askitall may, from time to time, be required to undertake financial crime screening to comply with applicable laws or regulations, in such circumstances, User agree to assist Askitall and cooperate with any requests for information.

NON-CIRCUMVENTION
All the registered professionals on Askitall platform including but not limited to lawyer, legal consultant, Chartered Accountants, Company Secretary or any other professional as the case may be(referred to as “Advisor”) registered on Askitall platform shall not, under any circumstances, seek to obtain contact details from the clients and/or Advisor using the Askitall website and/or app hereinafter referred as Askitall Platform nor shall they share any kind of contact details including but not limited to phone, email, twitter, facebook, office address etc. with any user. All communications, financial transactions, and interactions between the professionals and the clients initiated through the Askitall must occur exclusively on Askitall platform's built-in communication tools. This is to maintain the integrity of the platform, to ensure the privacy and security of all parties involved, and to comply with data protection laws and regulations. Any circumvention of the platform directly or indirectly is not encouraged. Askitall reserves the right to seek details from the platform users and may choose to implement any kind of Artificial Intelligence equipped tool without compromising on the confidentiality of the platform to specifically to monitor non-circumvention. This will be done while respecting the privacy of the Advisor and the user, in accordance with Askitall Privacy Policy.

OFF-BOARDING

Breach of any policy may lead to immediate suspension or termination of the User or Advisor Askitall account, with or without any prior notice.

UNAUTHORIZED USE OF YOUR ACCOUNT AND FRAUDULENT ACTIVITY

User agree to notify Askitall immediately upon becoming aware of any fraudulent activity or theft of Askitall Credit. Notifications may be made to Askitall via the email, post or contact number. User are solely responsible for informing your financial institution or payment services provider of any fraud related to your payment card. User agree that Askitall is not responsible for unauthorized use of Askitall Credit. Askitall reserves the right (in its sole and absolute discretion) to suspend or void any transaction or use of Askitall Credit, where Askitall suspects that Askitall Credit was obtained or used fraudulently, unlawfully, illegally, involves criminal activity or that User are otherwise in violation of these Askitall payment, cancellation & refund policy.

MODIFICATION TO TERMS

Askitall may modify any of these Askitall PAYMENT, CANCELLATION & REFUND POLICY at any time, with or without notice to User, subject to applicable law. The most current terms shall be effective upon Askitall posting of such modified Askitall PAYMENT, CANCELLATION & REFUND POLICY. User use of any portion of Askitall Credit after such posting constitutes your acceptance of the modified Askitall PAYMENT, CANCELLATION & REFUND POLICY. 

DISPUTE RESOLUTION

The governing law will be as set out in accordance with the Terms and Conditions of use for ASKTIALL. All disputes will be resolved in accordance with the Terms and Conditions.

In the majority of cases, there is NO other situation under which Askitall.co will provide a refund, except for the condition expressly mentioned above under Policy. In exceptional circumstances, the Askitall.co team will work with User to reach a mutually acceptable solution. Askitall.co intends to be objective, fair and reasonable in resolving such situations in booking to maintain a positive and harmonious relationship with User. The maximum refund amount claimable from Askitall.co cannot exceed the amount paid by the User at Askitall.co for availing the Advisor's service(s).

the Company nor its Advisors shall be held liable for any financial loss, damages, or adverse outcomes resulting from the User's reliance on the advice or suggestions provided through the Website. The User assumes full responsibility for any decisions made based on the consultation received, understanding that the Company and its Advisors do not guarantee the accuracy, suitability, or effectiveness of the information provided. It is recommended that Users exercise caution and conduct their own research or seek professional financial advice before implementing any recommendations.

No refund in any case whatsoever can be claimed from Askitall.co for the amount paid to the Advisor directly by the User"s.

Please note that this policy is subject to change and we recommend reviewing it regularly for any updates. Thank User for your understanding
  ...`;
  return (
    <div>
        <NavbarWebsite/>
        <div className='max-w-6xl w-full mx-auto'>
{/* <p className='lg:text-2xl font-medium mx-auto w-full'>
        TERMS & CONDITIONS
</p> */}
 <PolicyFormatter policyText={policyText} />

        </div>
    </div>
  )
}

export default RefundPolicy