import React from "react";
import NavbarWebsite from "./Navbar/NavbarWebsite1";
import Footer from "../pages/Website/Footer";

const Disclaimer = () => {
  return (
    <>

    <NavbarWebsite/>
    <div className="max-w-6xl h-screen lg:px-0 px-4 lg:h-full w-full mx-auto">
      <p className="text-center lg:text-2xl ">Disclaimer</p>
      <br className="lg:block hidden"  />
      <p>
        ASKITALL is a platform that facilitates connections between users
        seeking legal and financial guidance and independent legal and financial
        professionals. ASKITALL does not engage in the provision of legal,
        financial, or advisory services itself, nor does it act as an
        intermediary in any such transactions. 
        <br className="lg:block hidden"  /><br/>
        The legal and financial
        professionals available on ASKITALL operate independently and are not
        employees, agents, or representatives of ASKITALL. ASKITALL does not
        endorse or guarantee the qualifications, expertise, or services of any
        professional on the platform. 
        <br className="lg:block hidden"  /><br/>
        ASKITALL assumes no responsibility for the
        advice, services, or conduct of the independent professionals available
        on the platform. Users engage with professionals at their own risk and
        discretion, and ASKITALL shall not be liable for any damages or losses
        arising from such engagements. 
        <br className="lg:block hidden"  /><br/>
        User`s should exercise caution and
        diligence when relying on advice or services provided by professionals
        or advisor on ASKITALL.
        <br className="lg:block hidden"  /><br/>
         While ASKITALL endeavors to maintain the
        confidentiality of user information, users should be aware that
        communications on the platform may not be privileged or confidential.
        <br className="lg:block hidden"  /><br/>
        ASKITALL cannot guarantee the security of information exchanged through
        the platform.
        <br className="lg:block hidden"  /><br/>
         By using ASKITALL and engaging with professionals or
        advisors on the platform, users acknowledge that they have read,
        understood, and agreed to abide by all the terms and conditions and
        policies of ASKITALL.
      </p>
      
    </div>
    <Footer/>
    </>
  );
};

export default Disclaimer;
