import React from "react";
import Footer from "../pages/Website/Footer";
import NavbarWebsite from "./Navbar/NavbarWebsite1";

const Contact = () => {
  return (
    <>
      <NavbarWebsite />
      <div>
        <section className="bg-white ">
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2 className="mb-4 lg:text-3xl tracking-tight font-medium text-center text-black">
              Contact Us
            </h2>
            <p className="mb-8 lg:mb-16 font-light text-center text-black sm:text-xl">
              Got a technical issue? Want to send feedback about a beta feature?
              Need details about our Business plan? Let us know.
            </p>
            <div className="flex flex-col justify-start gap-y-6">
            <div className="flex text-xl font-medium  border-b border-gray-500  text-black flex-col justify-start gap-y-2 py-2">
              <div>Name: Dhaval Shah</div>
              Number: +91-9619818332
            </div>
            <div className="flex text-xl font-medium  border-b border-gray-500  text-black flex-col justify-start gap-y-2py-2">
              <div>Name: Chirag Shah</div>
              Number: +91-9619408885
            </div>
            <div className="flex text-xl font-medium border-b border-gray-500   text-black flex-col justify-start gap-y-2py-2">
              <div>Address:</div>
              25/26, Lohana Bhuvan, Pali ram road, behind BMC office,
              Andheri(w), Mumbai-400058
            </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
